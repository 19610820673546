import { useQuery } from '@tanstack/react-query'

import { IPurchased } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getPurchasedList = async ({
  originalsId,
  ordNo,
}: {
  originalsId?: string
  ordNo?: string
}): Promise<IPurchased> => {
  const url = `/api/v1/public/metadata/${originalsId}/check-possibility/purchased`

  const {
    data: { data },
  } = await withAxios<IBaseResponse<IPurchased>>({
    url,
    method: 'GET',
    params: {
      ordNo,
    },
  })

  return data
}

export const useGetPurchasedList = (isNeeded: boolean, originalsId?: string, ordNo?: string) =>
  useQuery(
    ['useGetPurchasedList', { originalsId }],
    () => getPurchasedList({ originalsId, ordNo }),
    {
      enabled: originalsId !== undefined && ordNo !== undefined && isNeeded,
      refetchOnWindowFocus: false,
    },
  )
