function NotEnabled() {
  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span>정상적인 접근이 아닙니다.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotEnabled
