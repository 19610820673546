/* eslint-disable @next/next/no-sync-scripts */
import { useOs } from '@mantine/hooks'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'
import { usePostOriginalsCardPaymentsCallback } from '@/shared/services/originals'
import { IGetOriginalsPaymentCallbackResponse, ISelectedCheckoutOptionItem } from '@/shared/types'
import { LocalStorage } from '@/shared/utils'

const CheckoutHead = ({ selectOptions }: { selectOptions: ISelectedCheckoutOptionItem[] }) => {
  const os = useOs()
  const router = useRouter()
  const { originalsId } = router.query as {
    originalsId?: string
  }

  const ordNo = LocalStorage.getItem('ordNo')

  const styleSheet = useMemo(() => {
    if (os === 'android' || os === 'ios' || os === 'undetermined') return 'mobile'
    else return 'pc'
  }, [os])

  const { mutate: postCheckoutCardCallback } = usePostOriginalsCardPaymentsCallback()
  const { setDialog } = useDialog()

  useEffect(() => {
    const hpayClose = () => {
      const pgBg = document.getElementById('lgpg-bg')
      const pgPopup = document.getElementById('easyXDM_dlp_provider')

      if (pgPopup !== null && typeof pgPopup !== undefined) {
        pgPopup.remove()
      }
      if (pgBg !== null && typeof pgBg !== undefined) {
        pgBg.classList.remove('active')
      }
    }

    const hpaySubmit = () => {
      const form = document.getElementsByName('payForm')[0] as HTMLFormElement | undefined
      if (!originalsId || ordNo === null) return

      const formData = new FormData(form)

      const params = new URLSearchParams()

      for (const key of formData.keys()) {
        if (key !== null) {
          params.append(key, `${formData.get(key)}`)
        }
      }

      params.append('ordNo', ordNo)
      params.append('originalsId', originalsId)
      params.append('selectOptions', JSON.stringify(selectOptions))

      hpayClose()

      setDialog(ManagedDialog.waitingForPayments)

      postCheckoutCardCallback(
        { formData: params },
        {
          onSuccess: (data: IGetOriginalsPaymentCallbackResponse) => {
            setDialog(null)

            const { url } = data
            router.replace(url)
          },
          onError: (e: any) => {
            setDialog(null)
          },
        },
      )
    }

    window.hpaySubmit = hpaySubmit
    window.hpayClose = hpayClose
  }, [ordNo, originalsId, postCheckoutCardCallback, router, selectOptions, setDialog])

  return (
    <Head>
      <script src="https://pg.bluewalnut.co.kr//dlp/scripts/lib/easyXDM.min.js"></script>
      <script src="https://pg.bluewalnut.co.kr//dlp/hpay_tr.js"></script>
      <script src="https://pg.bluewalnut.co.kr//dlp/scripts/lib/json2.js"></script>
      <script src="https://pg.bluewalnut.co.kr//dlp/hpayUtil.js"></script>
      <script src="https://code.jquery.com/jquery-1.12.4.js"></script>
      <link
        rel="stylesheet"
        href={`https://pg.bluewalnut.co.kr//dlp/css/${styleSheet}/hpay.css`}
        type="text/css"
      />
    </Head>
  )
}
export default CheckoutHead
