import AdultDialog from './AdultDialog'
import CancelRefundDialog from './CancelRefundDialog'
import CanNotUsePaymentDialog from './CanNotUsePaymentDialog'
import GoAppHome from './GoAppHomeDialog'
import QrDialog from './QrDialog'
import SoldOutDialog from './SoldOutDialog'
import TermsOfUseAndPayment from './TermsOfUseAndPayment'
import WaitingForPaymentsDialog from './WaitingForPaymentsDialog'
import WaitingOrderDialog from './WaitingOrderDialog'

export {
  AdultDialog,
  CancelRefundDialog,
  CanNotUsePaymentDialog,
  GoAppHome,
  QrDialog,
  SoldOutDialog,
  TermsOfUseAndPayment,
  WaitingForPaymentsDialog,
  WaitingOrderDialog,
}
