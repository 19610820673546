/* eslint-disable @next/next/no-img-element */
import { optimizeImage } from '@/shared/utils'

interface IItemInfoProps {
  thumbnail?: string
  hostName?: string
  title?: string
  renderPrice: () => JSX.Element | null
}

const ItemInfo = ({ thumbnail, hostName, title, renderPrice }: IItemInfoProps) => {
  return (
    <div className="flex justify-between items-center mt-[40px]">
      <div className="w-full h-[100px] flex items-center lg:gap-8 gap-4">
        <div className="lg:min-w-[100px] min-w-[80px] lg:min-h-[100px] min-h-[80px] rounded-md text-center">
          <img
            src={thumbnail ? optimizeImage(thumbnail) : '/img/replace-imgs/item-card-replace.png'}
            alt="original item"
            className="rounded-md lg:w-[100px] w-20 lg:h-[100px] h-20 object-cover aspect-square"
            onError={e => {
              e.currentTarget.src = '/img/replace-imgs/item-card-replace.png'
            }}
          />
        </div>
        <div className="w-full sm:text-base text-xs">
          <div className="text-textSecondary lg:text-base text-sm">{hostName ?? '-'}</div>
          <div className="text-textPrimary font-bold lg:text-2xl text-lg w-full mt-1 whitespace-pre-line">
            {title ?? '-'}
          </div>
          {renderPrice()}
        </div>
      </div>
    </div>
  )
}

export default ItemInfo
