import { useQuery } from '@tanstack/react-query'

import { IGetUseChannelTalkResponseType } from '@/shared/types/strapi'
import { LocalStorage } from '@/shared/utils'
import withAxios from '@/shared/utils/api'

export const getUseChannelTalk = async ({ isDev }: { isDev: boolean }) => {
  const STRAPI_BASE_URL =
    LocalStorage.getItem('strapiBaseUrl') ??
    process.env.STRAPI_BASE_URL ??
    'https://strapi.konkrit.io'

  const publicationState = isDev ? 'preview' : 'live'
  const url = `${STRAPI_BASE_URL}/api/use-channel-talk?populate=deep&publicationState=${publicationState}`
  const {
    data: { originals },
  } = await withAxios<IGetUseChannelTalkResponseType>({ url })
  return originals
}

export const useGetUseChannelTalk = (pathname: string, isDev: boolean) =>
  useQuery([pathname, 'getUseChannelTalk', { isDev }], () => getUseChannelTalk({ isDev }), {
    refetchOnWindowFocus: false,
  })
