import { useMemo } from 'react'

import { LocalStorage } from '../utils'

export const useConfig = () => {
  const canUseVConsole = useMemo(() => {
    if (typeof window === undefined) return process.env.USE_VCONSOLE === 'true'
    else return LocalStorage.getItem('useVConsole') === 'true'
  }, [])

  const apiBaseUrl = useMemo(() => {
    if (typeof window === undefined)
      return process.env.API_BASE_URL ?? 'https://api.polygon.croffle.me'
    else return LocalStorage.getItem('apiBaseUrl') ?? 'https://api.polygon.croffle.me'
  }, [])

  const isDev = useMemo(() => {
    return apiBaseUrl.includes('mumbai')
  }, [apiBaseUrl])

  const channelTalkPluginKey = useMemo(() => {
    if (typeof window === undefined) return process.env.CHANNEL_TALK_PLUGIN_KEY ?? ''
    else return LocalStorage.getItem('channelTalkPluginKey') ?? ''
  }, [])

  return {
    canUseVConsole,
    apiBaseUrl,
    isDev,
    channelTalkPluginKey,
  }
}
