import dynamic from 'next/dynamic'

import { ManagedDialog } from '@/shared/constants'

import {
  AdultDialog,
  CancelRefundDialog,
  CanNotUsePaymentDialog,
  GoAppHome,
  QrDialog,
  SoldOutDialog,
  TermsOfUseAndPayment,
  WaitingForPaymentsDialog,
} from './managed-dialogs'

export interface IDialogManagerProps {
  dialog: ManagedDialog | null
  closeDialog: () => void
}

const WaitingOrderDialogNoSSR = dynamic(() => import('./managed-dialogs/WaitingOrderDialog'), {
  ssr: false,
})

const DialogManager = ({ dialog, closeDialog }: IDialogManagerProps) => {
  return (
    <>
      <QrDialog isOpened={dialog === ManagedDialog.qr} closeDialog={closeDialog} />
      <WaitingForPaymentsDialog
        isOpened={dialog === ManagedDialog.waitingForPayments}
        closeDialog={closeDialog}
      />
      <WaitingOrderDialogNoSSR
        isOpened={dialog === ManagedDialog.waitingOrder}
        closeDialog={closeDialog}
      />
      <SoldOutDialog isOpened={dialog === ManagedDialog.soldout} closeDialog={closeDialog} />
      <TermsOfUseAndPayment
        isOpened={dialog === ManagedDialog.termsOfUseAndPayment}
        closeDialog={closeDialog}
      />
      <CanNotUsePaymentDialog
        isOpened={dialog === ManagedDialog.canNotUsePayment}
        closeDialog={closeDialog}
      />
      <CancelRefundDialog
        isOpened={dialog === ManagedDialog.cancelRefund}
        closeDialog={closeDialog}
      />
      <AdultDialog isOpened={dialog === ManagedDialog.adult} closeDialog={closeDialog} />
      <GoAppHome isOpened={dialog === ManagedDialog.goAppHome} closeDialog={closeDialog} />
    </>
  )
}

export default DialogManager
