import { useCallback, useEffect } from 'react'

import Dialog from '@/components/core-ui/Dialog'
import { useWaitingRoomContext } from '@/shared/contexts'
import { IDialogProps } from '@/shared/types'

const WaitingOrderDialog = ({ isOpened, closeDialog }: IDialogProps) => {
  const { waitingRoomInfo, startTimer, stopTimer, setWaitingRoomInfo, count } =
    useWaitingRoomContext()

  useEffect(() => {
    if (waitingRoomInfo !== null && isOpened) {
      startTimer(waitingRoomInfo.totalCount, waitingRoomInfo.estimateSec)
    }
  }, [isOpened, startTimer, waitingRoomInfo])

  const handleClose = useCallback(() => {
    closeDialog()
    setWaitingRoomInfo(null)
    stopTimer()
  }, [closeDialog, setWaitingRoomInfo, stopTimer])

  if (waitingRoomInfo === null) return null

  return (
    <Dialog isOpen={isOpened} handleClose={handleClose} isClickOutsideCloseBlocked>
      <div className="w-[280px] md:w-80 max-w-xs">
        <div className="mb-8">
          <div className="text-sm text-center leading-tight text-white">
            <p>나의 대기순서</p>
            <h1 className="text-4xl font-semibold leading-tight mt-2">{count}번째</h1>
          </div>
          <ul className="list-disc list-outside text-xs ml-5 mt-6 leading-normal text-textSecondary">
            <li>
              현재 접속량이 많아 대기 중입니다. 잠시만 기다려 주시면 다음 단계로 자동 접속됩니다.
            </li>
            <li>새로고침 하거나 재접속 하시면 대기순서가 초기화됩니다.</li>
          </ul>
        </div>
      </div>
    </Dialog>
  )
}

export default WaitingOrderDialog
