import Script from 'next/script'

const VConsole = () => {
  return (
    <Script
      src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
      onLoad={() => {
        new window.VConsole()
      }}
    />
  )
}

export default VConsole
