import axios from 'axios'

export const getConfig = async () => {
  const {
    data: {
      apiBaseUrl,
      useVConsole,
      s3HostUrl,
      cdnHostUrl,
      strapiBaseUrl,
      channelTalkPluginKey,
      waitingRoomApiBaseUrl,
    },
  } = await axios.get('/api/config')
  return {
    apiBaseUrl,
    useVConsole,
    s3HostUrl,
    cdnHostUrl,
    strapiBaseUrl,
    channelTalkPluginKey,
    waitingRoomApiBaseUrl,
  }
}
