import { useEffect, useState } from 'react'

export const useIsReactNativeWebView: () => boolean | null = () => {
  const [isWebView, setIsWebView] = useState<boolean | null>(null)

  useEffect(() => {
    if (window.ReactNativeWebView) {
      setIsWebView(true)
    } else {
      setIsWebView(false)
    }
  }, [])

  return isWebView
}
