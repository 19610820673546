import { Fragment } from 'react'

import {
  ALL_AGREE_TERMS_BLACK_LIST,
  ORIGINALS_TERMS,
  ORIGINALS_TERMS_KEY,
} from '@/shared/constants'

import { AdultTerms, IAdultTermsProps } from './AdultTerms'
import { AllAgreeTerms } from './AllAgreeTerms'
import { CancelRefundTerms, ICancelRefundTermsProps } from './CancelRefundTerms'
import { IPersonalityTermsProps, PersonalityTerms } from './PersonalityTerms'

interface ITermsManagerProp {
  originalsId: string | undefined
  checkProps: {
    checkedAllAgree: boolean | null
    checkedCancelRefund: boolean | null
    checkedPersonalActivity: boolean | null
    checkedAdult: boolean | null
    handleClickAllAgree: () => void
    handleClickAdult: () => void
    handleClickCancelRefund: () => void
    handleClickPersonalActivity: () => void
  }
}

const TermsMappings = {
  [ORIGINALS_TERMS_KEY.CANCEL_REFUND]: ({
    checkedCancelRefund,
    handleClickCancelRefund,
  }: ICancelRefundTermsProps) => (
    <CancelRefundTerms
      checkedCancelRefund={checkedCancelRefund}
      handleClickCancelRefund={handleClickCancelRefund}
    />
  ),
  [ORIGINALS_TERMS_KEY.PERSONALITY]: ({
    checkedPersonalActivity,
    handleClickPersonalActivity,
  }: IPersonalityTermsProps) => (
    <PersonalityTerms
      checkedPersonalActivity={checkedPersonalActivity}
      handleClickPersonalActivity={handleClickPersonalActivity}
    />
  ),
  [ORIGINALS_TERMS_KEY.ADULT]: ({ checkedAdult, handleClickAdult }: IAdultTermsProps) => (
    <AdultTerms checkedAdult={checkedAdult} handleClickAdult={handleClickAdult} />
  ),
}

const TermsManager = ({ originalsId, checkProps }: ITermsManagerProp) => {
  const SHOW_ALL_AGREE_COUNT = 2
  if (originalsId === undefined) return null

  const hasTermsKey = Object.keys(ORIGINALS_TERMS).includes(originalsId)
  const termsNameList = hasTermsKey ? ORIGINALS_TERMS[originalsId] : ORIGINALS_TERMS['default']
  const hasAllAgreeTerms = SHOW_ALL_AGREE_COUNT <= termsNameList.length

  return (
    <>
      {ALL_AGREE_TERMS_BLACK_LIST.includes(originalsId) ? (
        <></>
      ) : (
        hasAllAgreeTerms && (
          <AllAgreeTerms
            checkedAllAgree={checkProps.checkedAllAgree}
            handleClickAllAgree={checkProps.handleClickAllAgree}
          />
        )
      )}

      {termsNameList.map((termsName, index) => (
        <Fragment key={`Terms-${termsName}-${index}`}>
          {TermsMappings[termsName](checkProps)}
        </Fragment>
      ))}
    </>
  )
}

export default TermsManager
