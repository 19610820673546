import Image from 'next/image'
import { useCallback, useMemo } from 'react'

import { ORIGINALS_PAYMENT_TYPE, ORIGINALS_PAYMENT_TYPE_NAME } from '@/shared/constants'
import { IOriginalsCheckoutType } from '@/shared/types'
import { cx, getBuyLimitText } from '@/shared/utils'

interface ISelectPaymentTypeProps {
  checkoutTypes: IOriginalsCheckoutType[]
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
  handleSelectPaymentType: (type: keyof typeof ORIGINALS_PAYMENT_TYPE) => void
}

const SelectPaymentType = ({
  checkoutTypes,
  selectedPaymentType,
  handleSelectPaymentType,
}: ISelectPaymentTypeProps) => {
  const getCheckoutTypeInfo = useCallback(() => {
    return checkoutTypes.find(checkoutType => checkoutType.paymentType === selectedPaymentType)
  }, [checkoutTypes, selectedPaymentType])

  const renderPaymentType = (selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE) => {
    if (selectedPaymentType === ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI) {
      return (
        <Image
          width={81.2}
          height={16}
          src="/img/icon/hyundai-card-logo.svg"
          alt="hyundai-card logo"
          className="mx-auto"
        />
      )
    }

    return <p className="text-center">{ORIGINALS_PAYMENT_TYPE_NAME[selectedPaymentType]}</p>
  }

  const renderBuyLimit = useCallback(() => {
    const selectedCheckoutTypeInfo = getCheckoutTypeInfo()

    if (selectedCheckoutTypeInfo === undefined) return null
    const { buyLimit, saleType } = selectedCheckoutTypeInfo

    const buyLimitText = getBuyLimitText({
      saleType,
      buyLimit,
      selectedPaymentType,
    })

    return (
      <ul className="mt-4 list-disc list-outside pl-[25px]">
        {Array.isArray(buyLimitText) ? (
          buyLimitText.map((text: string, index: number) => (
            <li
              key={`buy-limit-text-${text}-${index}`}
              className="text-sm text-textSecondary whitespace-pre-line break-keep">
              {text}
            </li>
          ))
        ) : (
          <li className="text-sm text-textSecondary">{buyLimitText}</li>
        )}
      </ul>
    )
  }, [getCheckoutTypeInfo, selectedPaymentType])

  const isValidPaymentType = useMemo(
    () =>
      checkoutTypes
        .filter(checkoutType => checkoutType.paymentType !== undefined)
        .filter(checkoutType => ORIGINALS_PAYMENT_TYPE[checkoutType.paymentType] !== undefined)
        .length > 0,
    [checkoutTypes],
  )

  if (!isValidPaymentType) return null

  return (
    <div className="w-full lg:text-base text-sm mt-10">
      <h1 className="w-full text-lg">결제 수단</h1>
      <div className={cx('mt-4', checkoutTypes.length > 1 ? 'grid grid-cols-2 gap-3' : '')}>
        {checkoutTypes.map(checkoutType => (
          <div
            key={`checkout-type-list-${checkoutType.paymentType}`}
            onClick={() => handleSelectPaymentType(checkoutType.paymentType)}
            className={cx(
              'border-[1px] rounded-lg py-4 cursor-pointer',
              checkoutType.paymentType === ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI ? 'col-span-2' : '',
              selectedPaymentType === checkoutType.paymentType
                ? 'border-borderPrimary bg-white bg-opacity-5'
                : 'border-borderQuarternary',
            )}>
            {renderPaymentType(checkoutType.paymentType)}
          </div>
        ))}
      </div>
      {renderBuyLimit()}
    </div>
  )
}

export default SelectPaymentType
