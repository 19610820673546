import axios from 'axios'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ManagedDialog, WAITING_ORDER_ACTION } from '../constants'
import { getWaitingRoom } from '../services/websocket'
import { IConnectionWebSocketResponse } from '../types'
import { LocalStorage, sendToSentryWithExtra } from '../utils'
import { useDialog } from './useDialog'

export const useWaitingRoom = () => {
  const router = useRouter()
  const { ordNo } = router.query as { ordNo?: string }
  const { setDialog } = useDialog()

  const [waitingRoomInfo, setWaitingRoomInfo] = useState<IConnectionWebSocketResponse | null>(null)

  const connectionId = useRef<string | null>(null)

  const [count, setCount] = useState<number>(0)
  const [estimateSec, setEstimateSec] = useState<number>(0)
  const timer = useRef<NodeJS.Timeout>()

  const nextActionRef = useRef<(() => void) | null>(null)

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current)
      timer.current = undefined
    }
  }, [])

  const decreaseCount = useCallback(() => {
    setCount(prevCount => {
      const throughPutCount = waitingRoomInfo?.throughPutCount ?? 15
      const newCount = prevCount - throughPutCount
      return newCount >= 0 ? newCount : 0
    })
    setEstimateSec(prevEstimateSec => (prevEstimateSec - 1 >= 0 ? prevEstimateSec - 1 : 0))
  }, [waitingRoomInfo?.throughPutCount])

  const startTimer = useCallback(
    (totalCount: number, estimateSec: number) => {
      if (timer.current) return

      setCount(totalCount)
      setEstimateSec(estimateSec)
      timer.current = setInterval(decreaseCount, 1000)
    },
    [decreaseCount],
  )

  const clearState = useCallback(() => {
    setCount(0)
    setEstimateSec(0)
    stopTimer()
    setWaitingRoomInfo(() => null)
  }, [stopTimer])

  const connectWaitingRoom = useCallback(
    (url: string, action: keyof typeof WAITING_ORDER_ACTION) => {
      const waitingRoomSocket = new WebSocket(url)

      waitingRoomSocket.addEventListener('open', () => {
        waitingRoomSocket.send(JSON.stringify({ action: WAITING_ORDER_ACTION[action], ordNo }))
      })
      waitingRoomSocket.addEventListener('message', event => {
        const dataFromServer = JSON.parse(event.data)
        if (typeof dataFromServer.totalCount === 'number') {
          setWaitingRoomInfo(dataFromServer)
          setDialog(ManagedDialog.waitingOrder)
        } else if (dataFromServer.action === WAITING_ORDER_ACTION.entry) {
          setDialog(null)
          clearState()
          if (typeof dataFromServer.connectionId === 'string') {
            connectionId.current = dataFromServer.connectionId
            LocalStorage.setItem('connectionId', dataFromServer.connectionId)
          }
          waitingRoomSocket.close()
        }
      })
      waitingRoomSocket.addEventListener('close', () => {
        if (typeof nextActionRef.current === 'function' && connectionId.current !== null) {
          nextActionRef.current()
          nextActionRef.current = null
        }
        clearState()
      })
    },
    [clearState, connectionId, ordNo, setDialog],
  )

  const handleRequestGetWaitingRoom = useCallback(
    async (action: keyof typeof WAITING_ORDER_ACTION) => {
      try {
        connectionId.current = null
        LocalStorage.removeItem('connectionId')
        clearState()
        const waitingRoomInfo = await getWaitingRoom()
        connectWaitingRoom(waitingRoomInfo, action)
      } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
          sendToSentryWithExtra(new Error('cannot get waiting room url info'))
        } else if (e instanceof Error) {
          sendToSentryWithExtra(e)
        }
      }
    },
    [clearState, connectWaitingRoom],
  )

  useEffect(() => {
    return () => {
      clearState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    handleRequestGetWaitingRoom,
    waitingRoomInfo,
    setWaitingRoomInfo,
    nextActionRef,
    startTimer,
    stopTimer,
    count,
    estimateSec,
    connectionId,
  }
}
