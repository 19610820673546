import { useRouter } from 'next/router'

import { ManagedDialog, ORIGINALS_PAYMENT_TYPE } from '../constants'
import { usePostOriginalsFreePaymentsCallback } from '../services/originals/checkoutFreeCallback'
import {
  IGetOriginalsPaymentCallbackResponse,
  IGetOriginalsPaymentResponse,
  ISelectedCheckoutOptionItem,
} from '../types'
import { LocalStorage, settlePay } from '../utils'
import { useDialog } from './useDialog'

export const usePayments = (paymentType: keyof typeof ORIGINALS_PAYMENT_TYPE | null) => {
  const router = useRouter()
  const { originalsId } = router.query as {
    originalsId?: string
  }
  const ordNo = LocalStorage.getItem('ordNo')

  const { mutate: postCheckoutFreeCallback } = usePostOriginalsFreePaymentsCallback()

  const { setDialog } = useDialog()

  const popupBankPayment = (paymentResponse: IGetOriginalsPaymentResponse) => {
    const el = document.getElementsByTagName('body')[0]
    const form = document.createElement('form')

    const exceptKeys = ['id', 'updatedAt', 'createdAt']

    form.setAttribute('name', 'sampleFm')

    for (const [key, value] of Object.entries(paymentResponse)) {
      if (!exceptKeys.includes(key)) {
        const input = document.createElement('input')
        input.setAttribute('name', key)
        input.setAttribute('value', value)
        input.setAttribute('type', 'hidden')
        form.appendChild(input)
      }
    }
    el.appendChild(form)
    settlePay.execute(form)
  }

  const getCheckoutFreeCallback = (
    _: IGetOriginalsPaymentResponse,
    selectOptions: ISelectedCheckoutOptionItem[],
  ) => {
    if (originalsId === undefined || ordNo === null) return
    setDialog(ManagedDialog.waitingForPayments)
    postCheckoutFreeCallback(
      { ordNo, originalsId, selectOptions },
      {
        onSuccess: (data: IGetOriginalsPaymentCallbackResponse) => {
          setDialog(null)
          const { url } = data
          router.replace(url)
        },
        onError: () => {
          setDialog(null)
        },
      },
    )
  }

  const popupCardPayment = (paymentResponse: IGetOriginalsPaymentResponse) => {
    const previousFormValue = document.getElementsByName('payForm')[0] as
      | HTMLFormElement
      | undefined

    if (previousFormValue) previousFormValue.remove()

    const body = document.getElementsByTagName('body')[0]
    const newFormValue = document.createElement('form')

    const exceptKeys = ['id', 'updatedAt', 'createdAt']

    newFormValue.setAttribute('name', 'payForm')

    for (const [key, value] of Object.entries(paymentResponse)) {
      if (!exceptKeys.includes(key)) {
        const input = document.createElement('input')
        input.setAttribute('name', key)
        input.setAttribute('value', value)
        input.setAttribute('type', 'hidden')
        newFormValue.appendChild(input)
      }
    }
    body.appendChild(newFormValue)
    window.goPay(newFormValue)
  }

  const mappingsPaymentFunc = {
    [ORIGINALS_PAYMENT_TYPE.BANK]: popupBankPayment,
    [ORIGINALS_PAYMENT_TYPE.FREE]: getCheckoutFreeCallback,
    [ORIGINALS_PAYMENT_TYPE.CARD]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: popupCardPayment,
    [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: popupCardPayment,
  }

  return {
    paymentFunc: paymentType === null ? null : mappingsPaymentFunc[paymentType],
  }
}
