import { cx } from '../utils'

export const MinusButton = ({
  isCanSubtractAmount = false,
  onClick,
  classNames,
}: {
  isCanSubtractAmount: boolean
  onClick: () => void
  classNames?: string
}) => {
  return (
    <div className={cx('cursor-pointer', classNames)} onClick={onClick}>
      <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.25 1.75H14.75C15.1642 1.75 15.5 1.41421 15.5 1C15.5 0.585786 15.1642 0.25 14.75 0.25H1.25C0.835786 0.25 0.5 0.585786 0.5 1C0.5 1.41421 0.835786 1.75 1.25 1.75Z"
          fill={isCanSubtractAmount ? 'white' : '#3F3F46'}
        />
      </svg>
    </div>
  )
}
