import { useCallback } from 'react'
import QRCode from 'react-qr-code'

import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'
import { LocalStorage } from '@/shared/utils'

function QrDialog({ isOpened, closeDialog }: IDialogProps) {
  const appLink = LocalStorage.getItem('appLink')

  const RenderQrCodeSection = useCallback(() => {
    if (appLink === null) return <></>

    return (
      <>
        <div className="gap-8 w-full mt-4 items-center justify-center lg:flex hidden">
          <div className="text-sm text-textSecondary bg-white rounded-2xl flex flex-col gap-[22px] p-8 items-center justify-center">
            <QRCode style={{ height: '200px', width: '200px' }} value={appLink} />
          </div>
        </div>
        <div className="flex w-full items-center justify-center gap-4 mt-4 lg:hidden">
          <button
            className="py-4 px-6 rounded-lg bg-white text-textBlack font-semibold"
            onClick={() => window.open(appLink)}>
            KONKRIT 다운로드
          </button>
        </div>
      </>
    )
  }, [appLink])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="lg:px-[84px] lg:py-2 py-6 px-[28px]">
        <div className="flex items-center justify-center lg:mt-0 mt-6">
          <img src="/img/logo/small-logo.png" alt="" />
        </div>

        <div className="w-full flex items-center justify-center mt-4 flex-col">
          <h1 className="flex justify-between text-white text-base lg:text-2xl font-semibold mb-[8px] whitespace-pre-wrap">
            KONKRIT 앱을 통해서 결제할 수 있어요
          </h1>
          <h2 className="text-textSecondary text-sm md:text-base text-center">
            KONKRIT 앱 다운로드 후 빠르게 결제하기
          </h2>
        </div>

        <RenderQrCodeSection />
      </div>
    </Dialog>
  )
}
export default QrDialog
