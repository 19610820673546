import { useCallback } from 'react'

import { APP_PATH_TYPE } from '@/shared/constants'
import { useConfig } from '@/shared/hooks'
import { goToAppUsingDeepLink } from '@/shared/utils'

import { Button } from '../core-ui'

function NotFound() {
  const { isDev } = useConfig()

  const handleGoToHomeButton = useCallback(() => {
    goToAppUsingDeepLink(APP_PATH_TYPE.HOME, isDev)
  }, [isDev])

  return (
    <div className="w-full mt-[60px] lg:mt-[132px]">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-1 font-normal leading-normal text-sm lg:text-lg font-Pretendard text-center">
            <span>원활한 서비스 사용을 위해 홈 화면으로 이동해주세요.</span>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            theme="Accent"
            text="홈으로 돌아가기"
            onClick={handleGoToHomeButton}
            className="w-[142px] lg:w-[170px] h-[41px] lg:h-[52px] text-sm lg:text-base"
          />
        </div>
      </div>
    </div>
  )
}

export default NotFound
