export const TOAST_MESSAGE = {
  NOTICE_CHEAP: '모든 DAY 예약권 구매 시, \n 3일권으로 구매하면 더 저렴합니다',
  ALERT_SELECT: '구매를 위한 옵션을 모두 선택해 주세요',
  ALERT_ADULT: '만 19세 이상 체크를 확인해 주세요',
  ALREADY_PURCHASED: '이미 구매한 옵션입니다',
  CANCEL_OPTION: '이전 티켓 선택이 해제되었어요',
  ALL_CHECK: '필수사항에 모두 동의해주세요',
  IMPOSSIBLE_PURCHASE: '구매 가능한 예약권이 없습니다',
  PAYMENT_ERROR:
    '서버 에러가 발생하였습니다. \n support@modernlion.io 메일을 \n 통해 문의 부탁드립니다.',
}
