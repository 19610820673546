import { useCallback, useEffect, useRef } from 'react'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'

function AdultDialog({ isOpened, closeDialog }: IDialogProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  const onFocusHandler = useCallback(() => {
    if (!isOpened || ref.current === null) return
    ref.current.scrollTop = 0
  }, [isOpened])

  useEffect(() => {
    setTimeout(() => {
      onFocusHandler()
    }, 100)
  }, [onFocusHandler])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div
        ref={ref}
        onFocus={onFocusHandler}
        className="w-[280px] h-[580px] overflow-y-auto scrollbar-hide md:w-80 max-w-xs scroll-smooth">
        <Dialog.Header title="만 19세 이상 결제 동의" />
        <div className="flex flex-col gap-3 py-5 text-sm text-textSecondary">
          <ul className="list-disc list-outside pl-5">
            <li>
              본인은 본 행사가 만 19세 이상 고객을 대상으로 하는 공연임을 숙지하였으며, 아래와 같은
              사항에 동의를 거부할 경우 일부 서비스를 제한 받을 수 있음에 동의합니다.
            </li>
            <li className="mt-6">
              미성년자(만 19세 미만) 고객은 본 행사의 예약권을 구매할 수 없습니다. (*2023년 기준
              2004년 9월 14일 출생자까지 구매 가능)
            </li>
            <li className="mt-6">
              KONKRIT 회원가입 정보 기준, 구매자가 만 19세 미만 관객으로 확인될 시 구매하신 예약권은
              자동 취소 처리됩니다.
            </li>
            <li className="mt-6">
              예약권 구매 취소 시, 구매 당시 선택한 결제 수단을 통해 전액 환불 됩니다.
            </li>
            <li className="mt-6">
              19세 미만 고객 구매 취소 건은 취소/환불 정책에 따른 수수료가 부과되지 않습니다.
            </li>
            <li className="mt-6">구매 취소 후, 환불 소요 기간은 결제 수단별 상이합니다.</li>
            <p className="ml-2">
              - 신용카드: 카드사 환불 정책에 따르며, 환불까지 영업일 기준 최대 7일 소요됩니다.
            </p>
            <p className="ml-2"> - 계좌이체: 1영업일 이내 결제 은행 계좌</p>
            <li className="mt-6">
              19세 미만 고객의 경우 예약권 소지 유무, 보호자 동반 여부와 관계없이 현장 관람
              불가합니다.
            </li>
          </ul>
        </div>
      </div>
      <Dialog.Footer>
        <Button text="확인" theme="Outlined" onClick={closeDialog} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default AdultDialog
