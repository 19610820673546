import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'

const SoldOutDialog = ({ isOpened, closeDialog }: IDialogProps) => {
  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog} isClickOutsideCloseBlocked>
      <div className="items-center md:w-80 w-[280px] text-textPrimary">
        <div className="w-full mb-4">
          <div className="md:text-2xl text-xl">매진 안내</div>
          <div className="text-white/[.64] md:text-base text-sm mt-1">
            <div>품목이 모두 매진되었습니다. 추후 공지를 기다려주세요.</div>
          </div>
        </div>
        <Dialog.Footer>
          <Button text="닫기" theme="Outlined" onClick={closeDialog} />
        </Dialog.Footer>
      </div>
    </Dialog>
  )
}

export default SoldOutDialog
