export const ETHER_CHAIN_ID: { [key: string]: number } = {
  HOMESTEAD_CHAIN_ID: 1,
  ROPSTEN_CHAIN_ID: 3,
  RINKEBY_CHAIN_ID: 4,
  GOERLI_CHAIN_ID: 5,
  POLYGON_CHAIN_ID: 137,
  DEV_CHAIN_ID: 2018,
  MUMBAI_CHAIN_ID: 80001,
}

export const ETHER_CHAIN_ID_HEX: { [key: string]: string } = {
  HOMESTEAD_CHAIN_ID: '0x1',
  ROPSTEN_CHAIN_ID: '0x3',
  RINKEBY_CHAIN_ID: '0x4',
  GOERLI_CHAIN_ID: '0x5',
  POLYGON_CHAIN_ID: '0x89',
  DEV_CHAIN_ID: '0x7e2',
  MUMBAI_CHAIN_ID: '0x13881',
}

export const CHAIN_ID: { [key: string]: string } = {
  '1': 'ETHEREUM',
  '3': 'ROPSTEN',
  '4': 'RINKEBY',
  '5': 'GOERLI',
  '137': 'POLYGON',
  '80001': 'MUMBAI',
}

export const ETHER_CHAIN_NAME = {
  goerli: 'goerli',
  homestead: 'homestead',
} as const
