import { Button } from '@/components/core-ui'

interface IProps {
  title: string
  text?: string
  onClick: () => void
}

// TODO @고영욱 추후에 마켓 쪽으로 로직 옮길 때 CommonErrorFallback 컴포넌트 사용해야해서 ErrorUI 제거해야 함
const ErrorUI = ({ title, text = '다시 불러오기', onClick }: IProps) => {
  return (
    <>
      <p className="text-center whitespace-pre-line">{title}</p>
      <Button text={text} size="sm" theme="Accent" className="w-[101px] mt-6" onClick={onClick} />
    </>
  )
}

export default ErrorUI
