import { ICheckoutOptionTitle } from '../types'

export const ORIGINALS_ID = {
  DAVINCI: 'davinci',
  ROONG_MATE: 'roong-mate',
  BUWOO_BUWOO: 'buwoo-buwoo',
}

export const CHECKOUT_OPTION_THEME = {
  flat: 'flat',
  radio: 'radio',
} as const

export const ORIGINALS_CHECKOUT_OPTION_TYPE: { [key: string]: keyof typeof CHECKOUT_OPTION_THEME } =
  {
    davinci: 'flat',
  }

export const ORIGINALS_CONTRACT_TYPE = {
  nft: 'nft',
  minter: 'minter',
} as const

export const ORIGINALS_SALE_TYPE = {
  pre: 'pre',
  earlyBird: 'earlyBird',
  public: 'public',
} as const

export const ORIGINALS_PAYMENT_TYPE = {
  BANK: 'BANK',
  FREE: 'FREE',
  CARD: 'CARD', // 전체 카드
  CARD_HYUNDAI: 'CARD_HYUNDAI', // 현대카드
  CARD_EVENT: 'CARD_EVENT', // 현대카드, 하나카드 제외한 카드
} as const

export const ORIGINALS_PAYMENT_TYPE_NAME = {
  BANK: '계좌이체',
  FREE: '무료',
  CARD: '카드결제', // 전체 카드
  CARD_HYUNDAI: '현대카드', // 현대카드
  CARD_EVENT: '카드결제', // 현대카드, 하나카드 제외한 카드
} as const

export const FLOATING_BAR_BUTTON_ACTION = {
  none: 'none',
  detail: 'detail',
  checkout: 'checkout',
  externalLink: 'externalLink',
  qr: 'qr',
} as const

export const OPTION_SELECT_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
}

export const ORIGINALS_OPTION_GROUP_NAME = {
  PIECE: '일일권',
  INTEGRATED: '통합권',
} as const

export const ORIGINALS_PROJECT_TYPE = {
  NORMAL: 'NORMAL',
  FESTIVAL: 'FESTIVAL',
} as const

export const PAY_ERROR = {
  ALREADY_PAID_OPTION: 'ALREADY_PAID_OPTION',
}

export const ORIGINALS_CHECKOUT_OPTION_TITLE: { [key: string]: ICheckoutOptionTitle } = {
  davinci: {
    OPTION_TITLE: '다빈치모텔 예약권 선택',
    OPTION_SUB_TITLE: 'DAY 예약권과 3일권 교차 선택 불가합니다.',
  },
}

export const ORIGINALS_TERMS_KEY = {
  PERSONALITY: 'PERSONALITY',
  CANCEL_REFUND: 'CANCEL_REFUND',
  ADULT: 'ADULT',
} as const

export const ORIGINALS_TERMS: { [originalsId: string]: (keyof typeof ORIGINALS_TERMS_KEY)[] } = {
  default: [ORIGINALS_TERMS_KEY.PERSONALITY],
  'buwoo-buwoo': [ORIGINALS_TERMS_KEY.PERSONALITY],
  'roong-mate': [ORIGINALS_TERMS_KEY.PERSONALITY],
  'malma-friends': [ORIGINALS_TERMS_KEY.PERSONALITY],
  davinci: [
    ORIGINALS_TERMS_KEY.CANCEL_REFUND,
    ORIGINALS_TERMS_KEY.PERSONALITY,
    ORIGINALS_TERMS_KEY.ADULT,
  ],
}

export const ALL_AGREE_TERMS_BLACK_LIST = ['davinci']
