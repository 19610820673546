import { useQuery } from '@tanstack/react-query'

import { IOriginalsScheduleResponse } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getOriginalsSchedule = async ({
  originalsId,
  isWebview,
}: {
  originalsId?: string
  isWebview: boolean | null
}) => {
  const url = `/api/v1/public/originals/${originalsId}/schedule?${
    isWebview === true ? `webview=${isWebview}` : ''
  }`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginalsScheduleResponse>>({ url })
  return data
}

export const useGetOriginalsSchedule = (
  pathname: string,
  isWebview: boolean | null,
  originalsId?: string,
) =>
  useQuery(
    [pathname, 'getOriginalsSchedule', { originalsId }],
    () => getOriginalsSchedule({ originalsId, isWebview }),
    {
      enabled: originalsId !== undefined && isWebview !== null,
      refetchOnWindowFocus: false,
    },
  )
