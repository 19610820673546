import { useQuery } from '@tanstack/react-query'

import { IFestivalOptions } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getCheckoutOptionList = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v1/public/groups/${originalsId}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IFestivalOptions>>({
    url,
  })

  return data
}

export const useGetCheckoutOptionList = (originalsId?: string) =>
  useQuery(
    ['useGetCheckoutOptionList', { originalsId }],
    () => getCheckoutOptionList({ originalsId }),
    {
      refetchOnWindowFocus: false,
      enabled: originalsId !== undefined,
    },
  )
