import { useCallback } from 'react'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { APP_PATH_TYPE } from '@/shared/constants'
import { IDialogProps } from '@/shared/types'
import { goToAppScreen } from '@/shared/utils'

const GoAppHome = ({ isOpened, closeDialog }: IDialogProps) => {
  const handleClickGoToApp = useCallback(() => {
    goToAppScreen(APP_PATH_TYPE.HOME)
  }, [])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="mb-[22px]">
        <Dialog.Header title="Home으로 이동하시겠습니까?" />
        <p className="text-sm text-textSecondary">확인 버튼을 누르면 Home으로 이동합니다.</p>
      </div>
      <Dialog.Footer>
        <Button text="닫기" theme="Outlined" onClick={closeDialog} />
        <Button
          text="확인"
          theme="Accent"
          onClick={() => {
            handleClickGoToApp()
            closeDialog()
          }}
        />
      </Dialog.Footer>
    </Dialog>
  )
}

export default GoAppHome
