import { useQuery } from '@tanstack/react-query'

import { IMetadataValueForOriginalsItem } from '@/shared/types'

import withAxios from '../../utils/api'

export const getMetadataValueForOriginalsItem = async ({
  originalsId,
  metadataKey,
}: {
  originalsId?: string
  metadataKey?: string
}) => {
  const url = `/api/v1/public/metadata/${originalsId}/${metadataKey}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IMetadataValueForOriginalsItem>>({
    url,
  })
  return data
}

export const useGetMetadataValueForOriginalsItem = (
  pathname: string,
  originalsId?: string,
  metadataKey?: string,
) =>
  useQuery(
    [pathname, 'getMetadataValueForOriginalsItem', { originalsId, metadataKey }],
    () => getMetadataValueForOriginalsItem({ originalsId, metadataKey }),
    {
      enabled: originalsId !== undefined && metadataKey !== undefined,
    },
  )
