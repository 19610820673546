import { useCallback } from 'react'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { APP_PATH_TYPE } from '@/shared/constants'
import { useConfig, useDialog } from '@/shared/hooks'
import { IDialogProps } from '@/shared/types'
import { goToAppUsingDeepLink } from '@/shared/utils'

const CanNotUsePaymentDialog = ({ isOpened, closeDialog }: IDialogProps) => {
  const { isDev } = useConfig()
  const { setDialog } = useDialog()

  const handleGoToHomeButton = useCallback(() => {
    goToAppUsingDeepLink(APP_PATH_TYPE.HOME, isDev)
    setDialog(null)
  }, [isDev, setDialog])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="items-center md:w-80 w-[280px] text-textPrimary">
        <div className="w-full mb-4">
          <div className="md:text-2xl text-xl">구매 불가</div>
          <div className="text-white/[.64] md:text-base text-sm mt-1">
            <div>
              현재 페이지에서는 구매가 불가능합니다. 앱으로 이동하여 앱 내 상세페이지부터 다시
              접근해주세요.
            </div>
          </div>
        </div>
      </div>
      <Dialog.Footer>
        <Button text="홈으로 가기" theme="Accent" onClick={handleGoToHomeButton} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default CanNotUsePaymentDialog
