import { ORIGINALS_PAYMENT_TYPE, ORIGINALS_SALE_TYPE } from '../constants'

export const getBuyLimitText = ({
  saleType,
  buyLimit,
  selectedPaymentType,
}: {
  saleType: keyof typeof ORIGINALS_SALE_TYPE
  buyLimit: number
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
}) => {
  const paymentTextMappings = {
    [ORIGINALS_SALE_TYPE.pre]: {
      [ORIGINALS_PAYMENT_TYPE.FREE]: `1회 결제 시 최대 수량은 ${buyLimit}장 입니다.`,
      [ORIGINALS_PAYMENT_TYPE.BANK]: ['사용자 개인 1회/1일 이체한도 내에서 이체 가능합니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD]: ['카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: [
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
      ],
      [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: [
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
        '현대카드 중 Gift카드, 무기명 법인카드 결제 시 할인 적용 불가합니다.',
        '결제 시 현대카드 M포인트 사용은 불가합니다.',
      ],
    },
    [ORIGINALS_SALE_TYPE.public]: {
      [ORIGINALS_PAYMENT_TYPE.FREE]: `1회 결제 시 최대 수량은 ${buyLimit}장 입니다.`,
      [ORIGINALS_PAYMENT_TYPE.BANK]: ['사용자 개인 1회/1일 이체한도 내에서 이체 가능합니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD]: ['카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: [
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
      ],
      [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: [
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
        '현대카드 중 Gift카드, 무기명 법인카드 결제 시 할인 적용 불가합니다.',
        '결제 시 현대카드 M포인트 사용은 불가합니다.',
      ],
    },
    // TODO @고영욱 다빈치모텔 사전판매(현대카드)에 맞춰 셋팅되어 있음
    [ORIGINALS_SALE_TYPE.earlyBird]: {
      [ORIGINALS_PAYMENT_TYPE.FREE]: `1회 결제 시 최대 수량은 ${buyLimit}장 입니다.`,
      [ORIGINALS_PAYMENT_TYPE.BANK]: ['사용자 개인 1회/1일 이체한도 내에서 이체 가능합니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD]: ['카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.'],
      [ORIGINALS_PAYMENT_TYPE.CARD_EVENT]: [
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
      ],
      [ORIGINALS_PAYMENT_TYPE.CARD_HYUNDAI]: [
        '선구매는 현대카드로만 결제 가능합니다.',
        '카드 결제는 사용자 개인 카드 이용한도 정책을 따릅니다.',
        '현대카드 중 Gift카드, 무기명 법인카드 결제 시 할인 적용 불가합니다.',
        '결제 시 현대카드 M포인트 사용은 불가합니다.',
      ],
    },
  }

  return paymentTextMappings[saleType][selectedPaymentType]
}
