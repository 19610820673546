import { OPTION_SELECT_TYPE } from '@/shared/constants'
import { IGroupOption, IPrice } from '@/shared/types'

export interface IFlatOptionItem {
  optionSelectType: keyof typeof OPTION_SELECT_TYPE
  optionTitle: string
  optionSubTitle: string
  price: IPrice[]
  buyLimit: number
  totalCount: number
  metadataKey: string
  maxMintingCount: number
}

export const makeFlatOptionList = ({ options }: { options: IGroupOption[] }): IFlatOptionItem[] => {
  const result = options.flatMap(({ option, optionSelectType }) => {
    return option.map(item => {
      return {
        ...item,
        optionSelectType,
      }
    })
  })

  return result
}
