import { ReactNode } from 'react'

import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'

interface IWaitingDialogProps extends IDialogProps {
  children?: ReactNode
  title?: string
}

function WaitingDialog({
  isOpened,
  closeDialog,
  children,
  title = '지갑을 확인해주세요',
}: IWaitingDialogProps) {
  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div className="w-80 max-w-xs flex flex-col items-center gap-6">
        <div className="w-14 h-14">
          <img src="/img/loading.svg" alt="loading" className="w-full h-full animate-spin" />
        </div>
        <div className="flex flex-col items-center gap-2">
          <h1 className="text-2xl leading-tight font-semibold text-white">{title}</h1>
          <p className="text-center text-base leading-normal font-normal text-textSecondary">
            {children}
          </p>
        </div>
      </div>
    </Dialog>
  )
}

export default WaitingDialog
