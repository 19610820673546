import { useLocalStorage } from '@mantine/hooks'

import { ManagedDialog } from '@/shared/constants'

export const useDialog = () => {
  const [dialog, setDialog] = useLocalStorage<ManagedDialog | null>({
    key: 'dialog',
    defaultValue: null,
  })

  return { setDialog, selectedDialog: dialog }
}
