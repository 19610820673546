import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'

import TermsAndConditions from './TermsAndConditions'

export interface IPersonalityTermsProps {
  checkedPersonalActivity: boolean | null
  handleClickPersonalActivity: () => void
}

export const PersonalityTerms = ({
  checkedPersonalActivity,
  handleClickPersonalActivity,
}: IPersonalityTermsProps) => {
  const { setDialog } = useDialog()

  if (checkedPersonalActivity === null) {
    return <></>
  }

  return (
    <TermsAndConditions
      hasDialog
      title={'개인정보 수집 및 결제 약관 동의 (필수)'}
      wrapperClassNames={'mt-3'}
      checkValue={checkedPersonalActivity}
      handleClickCheckValue={handleClickPersonalActivity}
      handleClickDialog={() => {
        setDialog(ManagedDialog.termsOfUseAndPayment)
      }}
    />
  )
}
