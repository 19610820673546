import { CHECKOUT_OPTION_THEME, ORIGINALS_OPTION_GROUP_NAME } from '@/shared/constants'
import { IOptionItem, IPurchasedItem } from '@/shared/types'

import { IFlatOptionItem } from './makeFlatOptionList'

export interface IGetIsPurchasedOtherOptionMappings {
  [CHECKOUT_OPTION_THEME.flat]: (props: IGetIsPurchasedOtherOptionByFlatProp) => boolean
  [CHECKOUT_OPTION_THEME.radio]: (props: IGetIsPurchasedOtherOptionByRadioProp) => boolean
}

interface IGetIsPurchasedOtherOptionByFlatProp {
  optionItem: IOptionItem
  allOptionList: IFlatOptionItem[] | undefined
  purchasedList: IPurchasedItem[]
}
interface IGetIsPurchasedOtherOptionByRadioProp {
  selectedOptionGroupName: string
  purchasedList: IPurchasedItem[]
}

export type IGetIsPurchasedOtherOptionProps =
  | IGetIsPurchasedOtherOptionByFlatProp
  | IGetIsPurchasedOtherOptionByRadioProp

// TODO @고영욱 더욱 유연하게 해야 함 (업보)
// - 통합권이 여러개 올 때 처리 해야 함
// - 제네릭으로 받아야 합니다.

/**
 * Radio 형태 일 때,
 * 일일권을 1개라도 구매한 경우,
 * 일일권을 모두 구매한 경우
 * 통합권 티켓을 Disabled 처리하는 util 로직 입니다.
 */
const getIsPurchasedOtherOptionByRadio = ({
  purchasedList,
  selectedOptionGroupName,
}: IGetIsPurchasedOtherOptionByRadioProp): boolean => {
  const purchasedMetaDataList = purchasedList[0]?.metadata ?? []

  if (purchasedMetaDataList.length === 0) return false

  const hasAllOptionItem = purchasedMetaDataList.some(purchasedItem =>
    purchasedItem.metadataKey.includes('-all'),
  )

  if (hasAllOptionItem) return true

  const countPurchasedOptionItem = purchasedMetaDataList.length

  if (
    selectedOptionGroupName === ORIGINALS_OPTION_GROUP_NAME.INTEGRATED &&
    countPurchasedOptionItem >= 1
  ) {
    return true
  }

  return false
}

/**
 * Flat 형태 일 때,
 * 일일권을 1개라도 구매한 경우,
 * 일일권을 모두 구매한 경우
 * 통합권 티켓을 Disabled 처리하는 util 로직 입니다.
 */
const getIsPurchasedOtherOptionByFlat = ({
  optionItem,
  allOptionList,
  purchasedList,
}: IGetIsPurchasedOtherOptionByFlatProp): boolean => {
  const { metadataKey } = optionItem
  const purchasedMetaDataList = purchasedList[0]?.metadata ?? []
  if (purchasedMetaDataList.length === 0) return false

  const hasAllOptionItem = purchasedMetaDataList.some(purchasedItem =>
    purchasedItem.metadataKey.includes('-all'),
  )

  if (hasAllOptionItem) return true

  const allSingleOptionListCount =
    allOptionList?.flatMap(item => item.metadataKey).filter(item => !item.includes('-all'))
      .length ?? 0

  const hasPurchasedAllSingleOptionListCount = purchasedMetaDataList.flatMap(
    item => !item.metadataKey.includes('-all'),
  ).length

  if (metadataKey.includes('-all') && 1 <= hasPurchasedAllSingleOptionListCount) return true

  if (allSingleOptionListCount === hasPurchasedAllSingleOptionListCount) return true

  return false
}

export const getIsPurchasedOtherOptionMappings: IGetIsPurchasedOtherOptionMappings = {
  [CHECKOUT_OPTION_THEME.flat]: ({ optionItem, allOptionList, purchasedList }) =>
    getIsPurchasedOtherOptionByFlat({ optionItem, allOptionList, purchasedList }),
  [CHECKOUT_OPTION_THEME.radio]: ({ selectedOptionGroupName, purchasedList }) =>
    getIsPurchasedOtherOptionByRadio({ selectedOptionGroupName, purchasedList }),
}
