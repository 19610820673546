import { useMutation } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import {
  IErrorData,
  IGetOriginalsPaymentResponse,
  IUpdateOriginalsCheckoutProps,
} from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const updateOriginalsCheckoutInfo = async ({
  ordNo,
  totalCount,
  paymentType,
  originalsId,
  selectOptions,
}: IUpdateOriginalsCheckoutProps) => {
  const url = `/api/v1/app/public/payments/checkout/${ordNo}`

  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetOriginalsPaymentResponse>>({
    url,
    method: 'PUT',
    data: {
      originalsId,
      paymentType,
      totalCount,
      selectOptions,
    },
  })

  return data
}

export const useUpdateOriginalsCheckoutInfo = () => {
  return useMutation<
    IGetOriginalsPaymentResponse,
    AxiosError<IErrorData>,
    IUpdateOriginalsCheckoutProps,
    unknown
  >(
    ['useUpdateOriginalsCheckoutInfo'],
    ({
      ordNo,
      totalCount,
      paymentType,
      originalsId,
      selectOptions,
    }: IUpdateOriginalsCheckoutProps) =>
      updateOriginalsCheckoutInfo({ ordNo, totalCount, paymentType, originalsId, selectOptions }),
  )
}
