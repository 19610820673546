import { useCallback, useMemo, useState } from 'react'

export const useAmount = (checkCanAddAmount: (amount: number) => boolean) => {
  const [amount, setAmount] = useState(1)

  const addAmount = useCallback(() => {
    if (checkCanAddAmount(amount)) return setAmount(amount => amount + 1)
  }, [amount, checkCanAddAmount])

  const subtractAmount = useCallback(() => {
    if (amount > 1) {
      setAmount(amount => amount - 1)
    }
  }, [amount])

  const clearAmount = useCallback(() => {
    setAmount(1)
  }, [])

  const isCanAddAmount = useMemo(() => {
    return checkCanAddAmount(amount)
  }, [amount, checkCanAddAmount])
  const isCanSubtractAmount = useMemo(() => {
    return amount > 1
  }, [amount])

  return {
    amount,
    addAmount,
    subtractAmount,
    clearAmount,
    isCanAddAmount,
    isCanSubtractAmount,
  }
}
