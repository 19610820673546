import axios from 'axios'

import { LocalStorage } from '@/shared/utils'

export interface IGetWaitingRoomResponse {
  waitingRoom: string
}

export const getWaitingRoom = async () => {
  const url =
    LocalStorage.getItem('waitingRoomApiBaseUrl') ??
    process.env.WAITING_ROOM_API_BASE_URL ??
    'https://b5mne4pbmsfmi3affb7tjd5iuy0hergr.lambda-url.ap-northeast-2.on.aws'

  const {
    data: { waitingRoom },
  } = await axios<IGetWaitingRoomResponse>({ method: 'GET', url })

  return waitingRoom
}
