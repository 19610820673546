import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { showToast } from '@/components/originals/checkout'
import { OPTION_SELECT_TYPE, ORIGINALS_OPTION_GROUP_NAME, TOAST_MESSAGE } from '@/shared/constants'
import { IGroupOption, IOptionItem, ISelectedOption } from '@/shared/types'

export const useOptions = ({ checkoutOptionList }: { checkoutOptionList: IGroupOption[] | [] }) => {
  const [selectedOptionList, setSelectedOptionList] = useState<ISelectedOption[]>([])
  const [selectedOptionGroupName, setSelectedOptionGroupName] = useState<string>(
    ORIGINALS_OPTION_GROUP_NAME.PIECE,
  )

  const countSingleTicket =
    checkoutOptionList.find(
      optionGroup => optionGroup.groupName === ORIGINALS_OPTION_GROUP_NAME.PIECE,
    )?.option.length ?? 0

  const hasAllClickOption = selectedOptionList.length === countSingleTicket && 0 < countSingleTicket

  useEffect(() => {
    if (hasAllClickOption) {
      showToast({ text: TOAST_MESSAGE.NOTICE_CHEAP, toastId: 'noticeCheap' })
    }
  }, [hasAllClickOption])

  const handleClickOptionGroupName = (clickOptionGroupName: string) => {
    setSelectedOptionGroupName(clickOptionGroupName)

    const hasSelectedOption = selectedOptionList.length !== 0
    const isDifferentClickOptionGroupName = clickOptionGroupName !== selectedOptionGroupName

    if (!isDifferentClickOptionGroupName) return

    if (!toast.isActive('cancelOption') && hasSelectedOption) {
      showToast({ text: TOAST_MESSAGE.CANCEL_OPTION, toastId: 'cancelOption' })
    }

    setSelectedOptionList([])
  }

  const handleClickOption = ({
    optionItem,
    productCount,
    optionSelectType,
  }: {
    optionItem: IOptionItem
    productCount: string
    optionSelectType: keyof typeof OPTION_SELECT_TYPE
  }) => {
    const hasClickedOption = selectedOptionList.some(
      item => item.metadataKey === optionItem.metadataKey,
    )

    if (hasClickedOption) {
      const filteredSelectedOptionList = selectedOptionList.filter(
        item => item.metadataKey !== optionItem.metadataKey,
      )
      setSelectedOptionList(filteredSelectedOptionList)
      return
    }

    if (optionSelectType === OPTION_SELECT_TYPE.MULTIPLE) {
      const newSelectedOptionList = selectedOptionList.concat({ ...optionItem, productCount })

      setSelectedOptionList(newSelectedOptionList)
      return
    }

    if (optionSelectType === OPTION_SELECT_TYPE.SINGLE) {
      const newSelectedOptionList = [{ ...optionItem, productCount }]

      setSelectedOptionList(newSelectedOptionList)
    }
  }

  return {
    selectedOptionList,
    selectedOptionGroupName,
    handleClickOption,
    handleClickOptionGroupName,
  }
}
