/* eslint-disable simple-import-sort/imports */
import { Dialog as HlDialog, Transition } from '@headlessui/react'
import Image from 'next/image'
import { Fragment, ReactNode, createContext, useContext, useRef } from 'react'

import { cx } from '@/shared/utils'
import { useRouter } from 'next/router'
import { ROUTE_PATH } from '@/shared/constants'

interface IDialogContentProps {
  className?: string
  fullScreenClassName?: string
  fullScreenTitle?: string
  handleClose(): void
  children: ReactNode
  theme?: 'primary' | 'tertiary'
  useMainLogo?: boolean
}

interface IDialogProps extends IDialogContentProps {
  isFullScreen?: boolean
  isOpen: boolean
  isClickOutsideCloseBlocked?: boolean
}

interface IDialogContextValues {
  isFullScreen?: boolean
}

const DialogContext = createContext<IDialogContextValues | null>(null)

function useDialogContext() {
  const context = useContext(DialogContext)

  if (context === null) {
    throw new Error('cannot find DialogContext')
  }

  return context
}

function PanelContent({
  className,
  fullScreenClassName,
  fullScreenTitle,
  children,
  handleClose,
  theme,
  useMainLogo,
}: IDialogContentProps) {
  const { push } = useRouter()
  const { isFullScreen } = useDialogContext()

  if (isFullScreen) {
    return (
      <div
        className={cx(
          theme === 'tertiary' ? 'bg-bgTertiary' : 'bg-bgPrimary',
          fullScreenClassName ?? 'p-[16px] transform transition-all min-h-screen',
        )}>
        <div className="overflow-y-auto">
          <div className="h-[64px]">
            <h1 className="flex justify-center items-center text-center h-full text-base font-semibold">
              {fullScreenTitle}
            </h1>
            {useMainLogo && (
              <div
                className="absolute left-[22px] top-[26px]"
                onClick={() => push(ROUTE_PATH.HOME)}>
                <Image src="/img/logo/beta-logo.svg" width={50.48} height={24} alt="logo" />
              </div>
            )}
            <button onClick={handleClose}>
              <Image
                width={24}
                height={24}
                className="absolute right-[22px] top-[23px]"
                src="/img/icon/close.svg"
                alt="close icon"
              />
            </button>
          </div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <div
      className={
        className ??
        'flex flex-col bg-bgTertiary rounded-2xl p-6 md:p-10 shadow-xl transform transition-all'
      }>
      {children}
    </div>
  )
}

function PanelContainer({ children }: { children: ReactNode }) {
  const { isFullScreen } = useDialogContext()

  if (isFullScreen) {
    return (
      <div className="fixed z-10 inset-0 h-full overflow-scroll scrollbar-hide">{children}</div>
    )
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto scrollbar-hide">
      <div className="min-h-full flex items-center justify-center">{children}</div>
    </div>
  )
}

function Dialog({
  isOpen,
  handleClose,
  isFullScreen,
  children,
  className,
  fullScreenClassName,
  fullScreenTitle,
  isClickOutsideCloseBlocked = false,
  theme = 'tertiary',
  useMainLogo = false,
}: IDialogProps) {
  const ref = useRef(null)

  const handleClickOutsideClose = () => {
    if (isClickOutsideCloseBlocked) return null
    handleClose()
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HlDialog
        initialFocus={ref}
        as="div"
        className="relative z-30"
        onClose={handleClickOutsideClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-bgPrimary bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <DialogContext.Provider value={{ isFullScreen }}>
          <PanelContainer>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 "
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4 ">
              <HlDialog.Panel className="h-full">
                <PanelContent
                  fullScreenClassName={fullScreenClassName}
                  fullScreenTitle={fullScreenTitle}
                  className={className}
                  handleClose={handleClose}
                  theme={theme}
                  useMainLogo={useMainLogo}>
                  {children}
                </PanelContent>
              </HlDialog.Panel>
            </Transition.Child>
          </PanelContainer>
        </DialogContext.Provider>
      </HlDialog>
    </Transition.Root>
  )
}

function DialogHeader({
  title,
  subTitle,
  children,
  handleClose,
  closeIcon,
}: {
  title?: string
  subTitle?: string | JSX.Element
  children?: ReactNode
  handleClose?(): void
  closeIcon?: boolean
}) {
  return (
    <div>
      <h1 className="flex justify-between text-white text-xl md:text-2xl font-semibold mb-[8px] whitespace-pre-wrap">
        {title}
        {closeIcon ? (
          <button onClick={handleClose}>
            <Image fill src="/img/icon/close.svg" alt="close icon" />
          </button>
        ) : null}
      </h1>
      {subTitle ? <h2 className="text-textSecondary text-sm md:text-base">{subTitle}</h2> : null}

      {children}
    </div>
  )
}

function DialogFooter({ children }: { children?: ReactNode }) {
  const { isFullScreen } = useDialogContext()

  return (
    <div className={cx(isFullScreen && 'fixed left-0 bottom-0  p-[16px]', 'w-full')}>
      <div className="flex justify-between mt-2 md:mt-4 space-x-2">{children}</div>
    </div>
  )
}

export default Object.assign(Dialog, { Header: DialogHeader, Footer: DialogFooter })
