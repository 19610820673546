import { isBoolean } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { ORIGINALS_TERMS, ORIGINALS_TERMS_KEY } from '../constants'

/**
 * originalsId 마다 약관 동의 체크 박스 값과 핸들러를 리턴하는 함수 입니다.
 * 약관 체크 값을 null로 관리하는 이유는 originalsId 마다 약관 동의 값이 달라져
 * 전체 동의 눌렀을 때, originalsId 마다 셋팅된 약관 동의 값을 모두 셋팅하기 위함입니다.
 * 그래서 isAllCheck에 null이면 true하게 만들어서, 유동적으로 전체동의 셋팅 로직을 개발했습니다.
 * @param originalsId
 */
export const useTermsAndConditions = ({ originalsId }: { originalsId: string | undefined }) => {
  const originalsIdValue = originalsId ?? ''
  const hasTermsKey = Object.keys(ORIGINALS_TERMS).includes(originalsIdValue)
  const termsList = hasTermsKey ? ORIGINALS_TERMS[originalsIdValue] : ORIGINALS_TERMS['default']

  const [checkedAllAgree, setCheckedAllAgree] = useState<boolean>(false)
  const [checkedCancelRefund, setCheckedCancelRefund] = useState<boolean | null>(
    termsList.includes(ORIGINALS_TERMS_KEY.CANCEL_REFUND) ? false : null,
  )
  const [checkedPersonalActivity, setCheckedPersonalActivity] = useState<boolean | null>(
    termsList.includes(ORIGINALS_TERMS_KEY.PERSONALITY) ? false : null,
  )
  const [checkedAdult, setCheckedAdult] = useState<boolean | null>(
    termsList.includes(ORIGINALS_TERMS_KEY.ADULT) ? false : null,
  )

  useEffect(() => {
    const isAllCheck =
      (checkedCancelRefund === null || (isBoolean(checkedCancelRefund) && checkedCancelRefund)) &&
      (checkedPersonalActivity === null ||
        (isBoolean(checkedPersonalActivity) && checkedPersonalActivity)) &&
      (checkedAdult === null || (isBoolean(checkedAdult) && checkedAdult))

    if (isAllCheck) {
      setCheckedAllAgree(true)
    } else {
      setCheckedAllAgree(false)
    }
  }, [checkedCancelRefund, checkedPersonalActivity, checkedAdult])

  const handleClickAllAgree = useCallback(() => {
    setCheckedAllAgree(!checkedAllAgree)

    setCheckedCancelRefund(checkedCancelRefund =>
      checkedCancelRefund !== null ? !checkedAllAgree : checkedCancelRefund,
    )
    setCheckedPersonalActivity(checkedPersonalActivity =>
      checkedPersonalActivity !== null ? !checkedAllAgree : checkedPersonalActivity,
    )
    setCheckedAdult(checkedAdult => (checkedAdult !== null ? !checkedAllAgree : checkedAdult))
  }, [checkedAllAgree])

  const handleClickPersonalActivity = useCallback(() => {
    setCheckedPersonalActivity(!checkedPersonalActivity)
  }, [checkedPersonalActivity])

  const handleClickCancelRefund = useCallback(() => {
    setCheckedCancelRefund(!checkedCancelRefund)
  }, [checkedCancelRefund])

  const handleClickAdult = useCallback(() => {
    setCheckedAdult(!checkedAdult)
  }, [checkedAdult])

  return {
    checkedAllAgree,
    checkedCancelRefund,
    checkedAdult,
    checkedPersonalActivity,
    handleClickAllAgree,
    handleClickPersonalActivity,
    handleClickCancelRefund,
    handleClickAdult,
  }
}
