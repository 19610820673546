import { IDialogProps } from '@/shared/types'

import WaitingDialog from '../common/WaitingDialog'

function WaitingForPaymentsDialog({ isOpened, closeDialog }: IDialogProps) {
  return (
    <WaitingDialog isOpened={isOpened} closeDialog={closeDialog} title="결제가 진행 중입니다" />
  )
}

export default WaitingForPaymentsDialog
