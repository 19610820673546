import '@/components/originals/lvc/styles/index.css'
import '@/styles/globals.css'
import 'react-toastify/dist/ReactToastify.css'

import { MantineProvider } from '@mantine/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useCallback, useEffect } from 'react'
import { Slide, ToastContainer } from 'react-toastify'

import { VConsole } from '@/components/dev'
import { DialogProvider } from '@/components/dialogs'
import AsyncBoundary from '@/components/utils/AsyncBoundary'
import { WaitingRoomProvider } from '@/shared/contexts'
import { useConfig } from '@/shared/hooks/useConfig'
import { getConfig } from '@/shared/services/config'
import { LocalStorage, sendToSentryWithExtra } from '@/shared/utils'

const App = ({ Component, pageProps }: AppProps) => {
  const queryClient = new QueryClient()

  const { canUseVConsole } = useConfig()

  const fetchConfig = useCallback(async () => {
    try {
      const {
        apiBaseUrl,
        useVConsole,
        s3HostUrl,
        cdnHostUrl,
        strapiBaseUrl,
        channelTalkPluginKey,
        waitingRoomApiBaseUrl,
      } = await getConfig()
      LocalStorage.setItem('apiBaseUrl', apiBaseUrl)
      LocalStorage.setItem('useVConsole', useVConsole)
      LocalStorage.setItem('s3HostUrl', s3HostUrl)
      LocalStorage.setItem('cdnHostUrl', cdnHostUrl)
      LocalStorage.setItem('strapiBaseUrl', strapiBaseUrl)
      LocalStorage.setItem('channelTalkPluginKey', channelTalkPluginKey)
      LocalStorage.setItem('waitingRoomApiBaseUrl', waitingRoomApiBaseUrl)
    } catch (e) {
      if (e instanceof Error) {
        sendToSentryWithExtra(e)
      }
    }
  }, [])

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])

  return (
    <>
      <Head>
        <title>대체 불가능한 NFT 거래 플랫폼 콘크릿 KONKRIT</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" />
        <meta name="theme-color" content="#18191a" />
        <meta
          name="description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:title" content="대체 불가능한 NFT 거래 플랫폼 KONKRIT" />
        <meta name="keywords" content="콘크릿, 컨크릿, 콘크릿 베타, 컨크릿 베타" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="NFT 거래, 더 KONKRIT(콘크릿)하게. 실생활 밀착형 유틸리티 기반의 NFT부터 PFP, Art, 연예인 NFT까지. 모두 NFT 마켓 플레이스 콘크릿에서 만나보세요."
        />
        <meta property="og:image" content="/og_image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="apple-touch-icon" href="/favicon_192.png" />

        <link rel="manifest" href="/manifest.json" />
      </Head>
      <AsyncBoundary>
        <>
          <QueryClientProvider client={queryClient}>
            <MantineProvider>
              <WaitingRoomProvider>
                <DialogProvider>
                  <Component {...pageProps} />
                </DialogProvider>
                <ToastContainer
                  toastClassName={() =>
                    'px-[14.5px] py-2 bg-[#FAFAFA] shadow-[0px 4px 8px]/[0.16] text-black rounded-lg items-center max-w-max mx-auto my-[2px]'
                  }
                  transition={Slide}
                  autoClose={2000}
                  position="bottom-center"
                  hideProgressBar={true}
                  pauseOnHover={true}
                  closeButton={false}
                />
              </WaitingRoomProvider>
            </MantineProvider>
          </QueryClientProvider>
          {canUseVConsole && <VConsole />}
        </>
      </AsyncBoundary>
    </>
  )
}

export default App
