import { useQuery } from '@tanstack/react-query'

import { IUseWaitingRoomResponseType } from '@/shared/types'
import { LocalStorage } from '@/shared/utils'
import withAxios from '@/shared/utils/api'

export const getUseWaitingRoom = async ({ isDev }: { isDev: boolean }) => {
  const STRAPI_BASE_URL =
    LocalStorage.getItem('strapiBaseUrl') ??
    process.env.STRAPI_BASE_URL ??
    'https://strapi.konkrit.io'

  const publicationState = isDev ? 'preview' : 'live'
  const url = `${STRAPI_BASE_URL}/api/use-waiting-rooms?populate=deep&publicationState=${publicationState}`
  const { data } = await withAxios<IUseWaitingRoomResponseType>({ url })

  return data
}

export const useGetUseWaitingRoom = (pathname: string, isDev: boolean) =>
  useQuery([pathname, 'getUseWaitingRoom', { isDev }], () => getUseWaitingRoom({ isDev }), {
    refetchOnWindowFocus: false,
  })
