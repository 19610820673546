import {
  CHECKOUT_OPTION_THEME,
  OPTION_SELECT_TYPE,
  ORIGINALS_PAYMENT_TYPE,
  ORIGINALS_PAYMENT_TYPE_NAME,
} from '@/shared/constants'
import {
  ICheckoutOptionTitle,
  IGroupOption,
  IOptionItem,
  IPurchasedItem,
  IStockInfos,
} from '@/shared/types'
import {
  cx,
  getIsPurchased,
  getIsPurchasedOtherOptionMappings,
  getIsSelectedOtherOption,
  getIsSoldOut,
  IFlatOptionItem,
  makeFlatOptionList,
  numberFormat,
} from '@/shared/utils'

interface IOptionListProps {
  options: IGroupOption[]
  originalsText: ICheckoutOptionTitle
  stockInfosData: IStockInfos | null
  purchasedList: IPurchasedItem[]
  selectedOptionList: IOptionItem[]
  selectedPaymentType: string | null
  selectedOptionGroupName: string
  theme: keyof typeof CHECKOUT_OPTION_THEME
  handleClickOption: ({
    optionItem,
    productCount,
    optionSelectType,
  }: {
    optionItem: IOptionItem
    productCount: string
    optionSelectType: keyof typeof OPTION_SELECT_TYPE
  }) => void
}

interface IOptionItemProps {
  value: number
  theme: keyof typeof CHECKOUT_OPTION_THEME
  allOptionList?: IFlatOptionItem[]
  type: keyof typeof ORIGINALS_PAYMENT_TYPE
  productCount: string
  percentage: string | null
  stockInfosData: IStockInfos | null
  optionItem: IOptionItem
  purchasedList: IPurchasedItem[]
  optionSelectType: keyof typeof OPTION_SELECT_TYPE
  selectedOptionGroupName: string
  selectedOptionList: IOptionItem[]
  handleClickOption: ({
    optionItem,
    productCount,
    optionSelectType,
  }: {
    optionItem: IOptionItem
    productCount: string
    optionSelectType: keyof typeof OPTION_SELECT_TYPE
  }) => void
}

interface ICheckIsPurchasedOtherOption {
  theme: keyof typeof CHECKOUT_OPTION_THEME
  optionItem: IOptionItem
  allOptionList: IFlatOptionItem[] | undefined
  selectedOptionGroupName: string
  purchasedList: IPurchasedItem[]
}

const checkIsPurchasedOtherOption = ({
  theme,
  optionItem,
  allOptionList,
  purchasedList,
  selectedOptionGroupName,
}: ICheckIsPurchasedOtherOption) => {
  const themeValue = CHECKOUT_OPTION_THEME[theme]
  const getIsPurchasedOtherOption = getIsPurchasedOtherOptionMappings[themeValue]
  const isPurchasedOtherOption = getIsPurchasedOtherOption({
    optionItem,
    allOptionList,
    purchasedList,
    selectedOptionGroupName,
  })

  return isPurchasedOtherOption
}

const OptionItemConditionPrice = ({
  isPurchased,
  isSoldOut,
  percentage,
  type,
  value,
}: {
  isPurchased: boolean
  isSoldOut: boolean
  percentage: string | null
  type: keyof typeof ORIGINALS_PAYMENT_TYPE
  value: number
}) => {
  return (
    <>
      {isSoldOut ? (
        <>
          <p className="text-base font-semibold text-right">매진</p>
        </>
      ) : isPurchased ? (
        <>
          <p className="text-base font-semibold text-right">이미 구매한 옵션입니다</p>
        </>
      ) : (
        <div className="flex flex-col justify-end">
          {percentage && (
            <p className="text-xs text-textAccent text-right">{`${ORIGINALS_PAYMENT_TYPE_NAME[type]} ${percentage}% 할인가`}</p>
          )}
          <p className="text-base font-semibold text-right">{`￦ ${numberFormat(value)}`}</p>
        </div>
      )}
    </>
  )
}

const OptionItem = ({
  type,
  theme,
  value,
  percentage,
  optionItem,
  allOptionList,
  purchasedList,
  productCount,
  stockInfosData,
  optionSelectType,
  selectedOptionList,
  selectedOptionGroupName,
  handleClickOption,
}: IOptionItemProps) => {
  const { optionTitle, optionSubTitle, metadataKey } = optionItem
  const isSoldOut = getIsSoldOut(metadataKey, stockInfosData?.stockInfos)
  const isPurchased = getIsPurchased(metadataKey, purchasedList)
  const isSelectedOtherOption = getIsSelectedOtherOption(selectedOptionList, optionItem)
  const isPurchasedOtherOption = checkIsPurchasedOtherOption({
    theme,
    optionItem,
    allOptionList,
    purchasedList,
    selectedOptionGroupName,
  })

  const isDisabled = isSoldOut || isPurchased || isPurchasedOtherOption || isSelectedOtherOption

  return (
    <div
      onClick={() => {
        if (isDisabled) return

        handleClickOption({ optionItem, productCount, optionSelectType })
      }}
      className={cx(
        'w-full min-h-[170px] border-[1px] flex flex-col rounded-lg mt-4 p-3 justify-between',
        selectedOptionList.some(item => item.optionTitle === optionTitle)
          ? 'border-primary bg-bgTertiary'
          : 'border-borderQuarternary',
        isDisabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer',
      )}>
      <div>
        <p className="text-sm whitespace-pre-line">{optionTitle}</p>
        <p className="min-h-[42px] mt-1 mb-4 text-sm whitespace-pre-line text-textSecondary">
          {optionSubTitle}
        </p>
      </div>

      <OptionItemConditionPrice
        type={type}
        value={value}
        isSoldOut={isSoldOut}
        isPurchased={isPurchased}
        percentage={percentage}
      />
    </div>
  )
}

const RadioOptionList = ({
  theme,
  options,
  stockInfosData,
  purchasedList,
  selectedOptionList,
  selectedPaymentType,
  selectedOptionGroupName,
  handleClickOption,
}: IOptionListProps) => {
  const defaultAmount = '1'
  const [selectedGroupOption] = options.filter(
    optionItem => optionItem.groupName === selectedOptionGroupName,
  )

  const { optionSelectType, option: selectedGroupOptionList } = selectedGroupOption

  return (
    <>
      <div className="flex items-center justify-between w-full mt-10">
        <h1 className="text-lg">날짜 선택</h1>
        {optionSelectType === OPTION_SELECT_TYPE.MULTIPLE && (
          <h1 className="text-sm text-textSecondary">중복 선택 가능</h1>
        )}
      </div>

      {selectedGroupOptionList.map((optionItem, index) => {
        const [selectedPrice] = optionItem.price.filter(
          priceItem => priceItem.type === selectedPaymentType,
        )

        const props = {
          ...selectedPrice,
          theme,
          stockInfosData,
          optionItem,
          productCount: defaultAmount,
          purchasedList,
          optionSelectType,
          selectedOptionList,
          selectedOptionGroupName,
          handleClickOption,
        }

        return (
          <OptionList.Item
            {...props}
            key={`radio-option-item-${props.optionItem.metadataKey}-${index}`}
          />
        )
      })}
    </>
  )
}

const FlatOptionList = ({
  theme,
  options,
  stockInfosData,
  purchasedList,
  originalsText,
  selectedOptionList,
  selectedPaymentType,
  selectedOptionGroupName,
  handleClickOption,
}: IOptionListProps) => {
  const defaultAmount = '1'
  const allOptionList = makeFlatOptionList({ options })
  const { OPTION_TITLE } = originalsText

  return (
    <>
      <div className="items-center justify-between w-full mt-10">
        <h1 className="text-lg">{OPTION_TITLE ?? '예약권 선택'}</h1>
      </div>

      {allOptionList.map((optionItem, index) => {
        const [selectedPrice] = optionItem.price.filter(
          priceItem => priceItem.type === selectedPaymentType,
        )

        const props = {
          ...selectedPrice,
          theme,
          allOptionList,
          stockInfosData,
          optionItem,
          productCount: defaultAmount,
          purchasedList,
          optionSelectType: optionItem.optionSelectType,
          selectedOptionList,
          selectedOptionGroupName,
          handleClickOption,
        }

        return (
          <OptionList.Item
            {...props}
            key={`flat-option-item-${props.optionItem.metadataKey}-${index}`}
          />
        )
      })}
    </>
  )
}

const OptionList = ({
  theme = `${CHECKOUT_OPTION_THEME.radio}`,
  options,
  stockInfosData,
  originalsText,
  purchasedList,
  selectedOptionList,
  selectedPaymentType,
  selectedOptionGroupName,
  handleClickOption,
}: IOptionListProps & {
  theme?: keyof typeof CHECKOUT_OPTION_THEME
}) => {
  const { OPTION_SUB_TITLE } = originalsText

  const props = {
    theme,
    options,
    stockInfosData,
    originalsText,
    purchasedList,
    selectedOptionList,
    selectedPaymentType,
    selectedOptionGroupName,
    handleClickOption,
  }

  if (theme === `${CHECKOUT_OPTION_THEME.flat}`) {
    return (
      <>
        <FlatOptionList {...props} />
        <ul className="pl-[25px] mt-4 text-sm list-disc list-outside">
          <li className="text-textSecondary whitespace-pre-line">{OPTION_SUB_TITLE ?? ''}</li>
        </ul>
      </>
    )
  }

  if (theme === `${CHECKOUT_OPTION_THEME.radio}`) {
    return <RadioOptionList {...props} />
  }

  return <></>
}

OptionList.Item = OptionItem
export default OptionList
