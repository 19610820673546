import { useEffect, useState } from 'react'

const useGetShowGoToAppDialog = () => {
  const [isShowGoToAppDialog, setIsShowGoToAppDialog] = useState(false)
  const listener = (event: any) => {
    if (!window.ReactNativeWebView || event.data === 'undefined') return

    const { type, message } = JSON.parse(event.data)

    if (type === 'ShowDialog' && message === 'HOME') {
      setIsShowGoToAppDialog(true)
    }
  }

  useEffect(() => {
    ;(() => {
      document.addEventListener('message', listener)
      window.addEventListener('message', listener)
    })()
    return () => {
      document.removeEventListener('message', listener)
      window.removeEventListener('message', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isShowGoToAppDialog,
    setIsShowGoToAppDialog,
  }
}

export default useGetShowGoToAppDialog
