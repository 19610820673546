import { useMutation } from '@tanstack/react-query'

import { IGetOriginalsPaymentCallbackResponse } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const postOriginalsCardPaymentsCallback = async ({
  formData,
}: {
  formData: URLSearchParams
}) => {
  const url = '/api/v1/app/public/card-payments/callback'
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetOriginalsPaymentCallbackResponse>>({
    url,
    headers,
    data: formData,
    method: 'POST',
  })

  return data
}

export const usePostOriginalsCardPaymentsCallback = () => {
  return useMutation(
    ['usePostOriginalsCardPaymentsCallback'],
    ({ formData }: { formData: URLSearchParams }) =>
      postOriginalsCardPaymentsCallback({ formData }),
  )
}
