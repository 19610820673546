import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { PropsWithChildren, ReactElement, useCallback } from 'react'

import { Spinner } from '../core-ui'
import { ErrorBoundary, ErrorFallback } from '../error'
import CustomSuspense from './CustomSuspence'

interface IAsyncBoundary {
  children: ReactElement
}

const AsyncBoundary = ({ children }: PropsWithChildren<IAsyncBoundary>) => {
  const { reset } = useQueryErrorResetBoundary()
  const resetHandler = useCallback(() => {
    reset()
  }, [reset])

  return (
    <ErrorBoundary resetQuery={resetHandler} errorFallback={<ErrorFallback />}>
      <CustomSuspense
        fallback={
          <div className="w-full h-screen flex justify-center items-center">
            <Spinner />
          </div>
        }>
        {children}
      </CustomSuspense>
    </ErrorBoundary>
  )
}

export default AsyncBoundary
