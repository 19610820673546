import { MinusButton, PlusButton } from '@/shared/assets'

interface ISelectAmountProps {
  amount: number
  addAmount: () => void
  subtractAmount: () => void
  isCanAddAmount: boolean
  isCanSubtractAmount: boolean
}

const SelectAmount = ({
  amount,
  addAmount,
  subtractAmount,
  isCanAddAmount,
  isCanSubtractAmount,
}: ISelectAmountProps) => {
  return (
    <>
      <div className="w-full lg:text-base text-sm mt-10">
        <h1 className="w-full text-lg">수량 선택</h1>
        <div className="mt-4 flex justify-end">
          <div className="lg:w-[120px] w-[96px] h-[40px] border border-1 rounded border-twPaletteGray400 flex lg:text-base text-sm">
            <MinusButton
              isCanSubtractAmount={isCanSubtractAmount}
              onClick={subtractAmount}
              classNames="w-10 h-10 flex items-center justify-center"
            />
            <div className="w-10 h-10 flex">
              <span className="m-auto text-sm font-Pretendard">{amount}</span>
            </div>
            <PlusButton
              isCanAddAmount={isCanAddAmount}
              onClick={addAmount}
              classNames="w-10 h-10 flex items-center justify-center"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectAmount
