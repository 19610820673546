export const ORIGINALS_PAYMENT_ERROR_CODE: { [key: string]: string } = {
  '101': '결제 중 재고가 소진되었어요',
  '102': '결제 중 오류가 발생했어요.',
  '201': '은행 점검 시간입니다. 점검 시간이 지난 뒤 다시 시도해 주세요.',
  '202': '계좌에 잔액이 부족합니다. 잔액 확인 후 다시 시도해 주세요.',
  '203': '결제 시스템 점검 중입니다. 점검 후 다시 시도해 주세요.',
  '204': '장시간 요청이 없어, 결제에 실패했어요.',
  '205': '계좌 오류가 발생했습니다. 계좌 확인 후 다시 시도해 주세요.',
  '206': 'ARS 인증에 실패했어요.',
  '207': '결제 금액 한도를 초과했습니다. 한도 확인 후 다시 시도해 주세요.',
  '208': '결제가 취소되었습니다.',
  '209': '예금주 확인 후 다시 결제해 주세요.',
  '210': '거래가 불가능한 은행입니다. 다른 은행으로 결제해 주세요.',
  '211': '잘못된 비밀번호입니다. 비밀번호를 확인해 주세요.',
  '212': '주민등록번호 혹은 사업자 번호를 다시 확인해 주세요.',
  '213': '이미 취소된 결제입니다.',
  '214': '환불이 진행 중입니다.',
  '215': '시스템 오류가 발생했어요. 잠시 후 다시 시도해 주세요.',
  '217': '결제 횟수 한도를 초과했습니다. 한도 확인 후 다시 시도해 주세요.',
  '218': '할부 개월 수를 초과했습니다. \n 회원사 할부 개월 수를 확인 후 다시 시도해 주세요.',
  '219': '유효기간에 오류가 있습니다. 확인 후 다시 시도해 주세요.',
  '220': '부분 취소가 불가능합니다. 확인 후 다시 시도해 주세요.',
  '221': '취소 시 오류가 발생하였습니다. 확인 후 다시 시도해 주세요.',
  '222': '개인 취소 한도를 초과하였습니다. 확인 후 다시 시도해 주세요.',
  '223': '회원사 취소 한도를 초과하였습니다. 확인 후 다시 시도해 주세요.',
  '224': '카드사 시간을 초과하였어요. 잠시 후 다시 시도해 주세요.',
  '225': '비밀번호에 오류가 있습니다. 확인 후 다시 시도해 주세요.',
  '226': '이미 결제 완료된 거래입니다.',
  '227': '환불에 문제가 발생했어요. 확인 후 다시 시도해 주세요.',
  '228': '결제를 위한 잔액이 부족합니다. 확인 후 다시 시도해 주세요.',
  '216': 'PG사의 정책 사유로 결제에 실패했어요.',
  '400': '이미 결제가 완료된 주문입니다.',
  '999': '동일한 오류가 반복된다면 \n support@modernlion.io로 문의해 주세요.',
}
