import * as Sentry from '@sentry/nextjs'
import axios from 'axios'

export const sendToSentryWithExtra = (error: Error | string, extra?: Record<string, unknown>) => {
  const scopeError = error instanceof Error ? error : new Error(error)
  const scope = new Sentry.Scope()

  if (extra) scope.setContext('extra', extra)

  if (axios.isAxiosError(error)) {
    const { config, code, request, response } = error
    scope.setContext('axios error', {
      config,
      code,
      request,
      response,
      detail: error.toJSON(),
    })
  }

  Sentry.captureException(scopeError, scope)
}
