export enum ManagedDialog {
  qr = 'qr',
  waitingForPayments = 'waitingForPayments',
  waitingOrder = 'waitingOrder',
  soldout = 'soldout',
  termsOfUseAndPayment = 'termsOfUseAndPayment',
  canNotUsePayment = 'canNotUsePayment',
  cancelRefund = 'cancelRefund',
  adult = 'adult',
  goAppHome = 'goAppHome',
}
