import { ORIGINALS_ID } from '../constants'
import { IPurchasedItem, IStockInfos } from '../types'

const SINGLE_TICKET_BY_DAVINCI = ['davinci-1', 'davinci-2', 'davinci-3']
const INTEGRATE_TICKET_BY_DAVINCI = ['davinci-all']

const getIsPossiblePurchaseByDavinci = ({
  stockInfos,
  purchasedList,
}: {
  stockInfos: IStockInfos
  purchasedList: IPurchasedItem[]
}) => {
  // 1. 단일권 3개 다 구매했을 때
  const isAllPurchasedSingleTicket =
    purchasedList.length === 0
      ? false
      : SINGLE_TICKET_BY_DAVINCI.every(metadataKey =>
          purchasedList[0].metadata.some(item => item.metadataKey === metadataKey),
        )

  // 2. 3일권 구매했을 때
  const isPurchasedIntegrateTicket =
    purchasedList.length === 0
      ? false
      : INTEGRATE_TICKET_BY_DAVINCI.every(metadataKey =>
          purchasedList[0].metadata.some(item => item.metadataKey === metadataKey),
        )

  // 3. 모든 티켓이 재고가 없을 때
  const isAllSoldOut = stockInfos.stockInfos.every(item => item.stockCount === 0)

  return !(isAllSoldOut || isAllPurchasedSingleTicket || isPurchasedIntegrateTicket)
}

export const getIsPossiblePurchaseMappings = {
  [ORIGINALS_ID.DAVINCI]: getIsPossiblePurchaseByDavinci,
}
