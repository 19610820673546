import { useCallback, useRef } from 'react'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'

function TermsOfUseAndPayment({ isOpened, closeDialog }: IDialogProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  const onFocusHandler = useCallback(() => {
    if (!isOpened || ref.current === null) return
    ref.current.scrollTop = 0
  }, [isOpened])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div onFocus={onFocusHandler} ref={ref} className="w-[280px] md:w-80 max-w-xs">
        <Dialog.Header title="개인 정보 수집 및 결제 약관 동의" />
        <div className="flex flex-col gap-3 py-5 text-sm text-textSecondary">
          <ul className="list-disc list-outside pl-5">
            <li>수집 목적: 주문, 결제 내역 제공</li>
            <li>항목: 결제 수단, 상품 정보</li>
            <li>보유 기간: 5년, 업무 목적 달성 후 파기 (전자상거래법 시행령 근거)</li>
            <br />
            <li>수집 목적: 본인 여부 확인</li>
            <li>항목: 방문 일시, 서비스 이용 기록 및 기기정보</li>
            <li>
              보유 기간: 5년, 업무 목적 달성 후 파기 (특정 금융거래정보의 보고 및 이용 등에 관한
              법률)
            </li>
            <br />
            <li>수집 목적: 고객 안내</li>
            <li>항목: 지갑 주소, 상품 정보</li>
            <li>보유 기간: 3개월, 업무 목적 달성 후 파기 (전자상거래법 시행령 근거)</li>
          </ul>
          <br />
          서비스 제공을 위해 필요한 최소한의 개인정보입니다. 동의를 해주셔야 서비스를 이용하실 수
          있으며, 동의하지 않으실 경우 서비스에 제한이 있을 수 있습니다.
        </div>
        <Dialog.Footer>
          <Button text="확인" theme="Outlined" onClick={closeDialog} />
        </Dialog.Footer>
      </div>
    </Dialog>
  )
}

export default TermsOfUseAndPayment
