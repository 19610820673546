import { useQuery } from '@tanstack/react-query'

import { IStockInfos } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getStockInfos = async ({
  originalsId,
}: {
  originalsId?: string
}): Promise<IStockInfos> => {
  const url = `/api/v1/public/originals/${originalsId}/stock`

  const {
    data: { data },
  } = await withAxios<IBaseResponse<IStockInfos>>({
    url,
    method: 'GET',
  })

  return data
}

export const useGetStockInfos = (originalsId?: string) =>
  useQuery(['useGetStockInfos', { originalsId }], () => getStockInfos({ originalsId }), {
    refetchOnWindowFocus: false,
    enabled: originalsId !== undefined,
  })
