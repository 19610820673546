import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'

import { useGetUseChannelTalk } from '../services/strapi/getUseChannelTalk'
import { LocalStorage } from '../utils'
import ChannelTalkService from '../utils/ChannelTalkService'
import { detectBrowser, detectDeviceType, detectOSName } from '../utils/detectUserAgent'
import { useConfig } from './useConfig'

const useChannelTalk = () => {
  const router = useRouter()
  const { originalsId } = router.query as { originalsId?: string }
  const storedOrdNo = LocalStorage.getItem('ordNo')

  const { channelTalkPluginKey, isDev } = useConfig()

  const { data } = useGetUseChannelTalk(router.pathname, isDev)

  const isUseChannelTalk = useMemo(() => {
    if (!originalsId || !data) return false
    return data.find(el => el.originalsId === originalsId && el.isUseChannelTalk)
  }, [data, originalsId])

  const initialChannelTalk = useCallback(() => {
    if (!isUseChannelTalk || !storedOrdNo) return

    const channelTalk = new ChannelTalkService()
    if (!window.ChannelIO) {
      channelTalk.loadScript()
    }
    channelTalk.boot({
      pluginKey: channelTalkPluginKey,
      memberId: storedOrdNo,
      createdAt: new Date(),
      web: {
        browserName: detectBrowser(navigator),
        osName: detectOSName(navigator),
        device: detectDeviceType(navigator),
      },
    })
    channelTalk.hide()

    return channelTalk
  }, [channelTalkPluginKey, isUseChannelTalk, storedOrdNo])

  const handleScroll = useCallback((channelTalk?: ChannelTalkService) => {
    const currentScrollPos = window.scrollY
    if (currentScrollPos > 400) {
      channelTalk?.show()
    }
  }, [])

  useEffect(() => {
    if (!isUseChannelTalk || !storedOrdNo) return

    const channelTalk = initialChannelTalk()

    const handleScrollEvent = () => {
      handleScroll(channelTalk)
      if (window.scrollY > 400) {
        window.removeEventListener('scroll', handleScrollEvent)
      }
    }

    window.addEventListener('scroll', handleScrollEvent)

    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
      channelTalk?.shutdown()
    }
  }, [handleScroll, initialChannelTalk, isUseChannelTalk, storedOrdNo])
}
export default useChannelTalk
