import { APP_PATH_TYPE } from '../constants'
import { sendToSentryWithExtra } from './sendToSentry'
import { navigate } from './webView'

export const goToAppScreen = (pathType: keyof typeof APP_PATH_TYPE) => {
  const urlMappings = {
    [APP_PATH_TYPE.HOME]: '/MainTabNavigator/ShopStack',
    [APP_PATH_TYPE.MYTEM]: '/MainTabNavigator/NFTStack',
    [APP_PATH_TYPE.PAYMENT_HISTORY]: '/PaymentReceiptListScreen',
    [APP_PATH_TYPE.DIVE]: null,
  }

  const key = urlMappings[pathType]
  if (key === null) {
    sendToSentryWithExtra(new Error('goToAppScript: key is null'), { pathType })
    return null
  }

  return navigate(key)
}
