export const detectBrowser = (navigator: Navigator) => {
  const userAgent = navigator.userAgent

  if (userAgent.includes('Opera') || navigator.userAgent.includes('OPR')) {
    return 'Opera'
  } else if (userAgent.includes('Chrome')) {
    return 'Chrome'
  } else if (userAgent.includes('Safari')) {
    return 'Safari'
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox'
  } else if (userAgent.includes('Edg')) {
    return 'Edge'
  } else {
    return 'Unknown'
  }
}

export const detectOSName = (navigator: Navigator) => {
  const userAgent = navigator.userAgent

  let operatingSystem = 'Not known'

  if (userAgent.includes('Win')) {
    operatingSystem = 'Windows OS'
  }
  if (userAgent.includes('Mac')) {
    operatingSystem = 'MacOS'
  }
  if (userAgent.includes('X11')) {
    operatingSystem = 'UNIX OS'
  }
  if (userAgent.includes('Linux')) {
    operatingSystem = 'Linux OS'
  }

  return operatingSystem
}

export const detectDeviceType = (navigator: Navigator) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      navigator.userAgent,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}
