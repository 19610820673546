import LocalStorage from './localstorage'

export default function optimizeImage(url: string | null, width?: number, height?: number) {
  const S3_HOST_URL =
    LocalStorage.getItem('s3HostUrl') ??
    process.env.S3_HOST_URL ??
    'https://konkrit-collection-item.s3.ap-northeast-2.amazonaws.com/'
  const CDN_HOST_URL =
    LocalStorage.getItem('cdnHostUrl') ??
    process.env.CDN_HOST_URL ??
    'https://d2rgc28tj3dddx.cloudfront.net/'

  if (url === null) return ''
  if (!url?.includes(S3_HOST_URL)) return url

  const imageReg = /.png|.jpg|.jpeg/g

  const replaceResizeUrl = (url: string) =>
    url.replace(imageReg, str => `__resize__${width ?? 500}x${height ?? 0}${str}`)

  const replaceHostUrl = (url: string) => {
    if (imageReg.test(url)) return url.replace(S3_HOST_URL, CDN_HOST_URL + 'thumbnail/')
    else return url.replace(S3_HOST_URL, CDN_HOST_URL)
  }

  const optimizeImageUrl = (url: string) => {
    const replacedHostUrl = replaceHostUrl(url)
    const replacedResizeUrl = replaceResizeUrl(replacedHostUrl)
    return replacedResizeUrl
  }

  return optimizeImageUrl(url)
}
