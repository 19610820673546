export const ROUTE_PATH = {
  HOME: '/',
  LOGIN: '/login',

  CURRENT_ACCOUNT: '/current-account',
  CURRENT_ACCOUNT_HIDDEN: '/current-account/hidden',
  CURRENT_ACCOUNT_LIKED: '/current-account/liked',
  CURRENT_ACCOUNT_ACTIVITY: '/current-account/activities',
  CURRENT_ACCOUNT_SENT_OFFER: '/current-account/sent-offers',
  CURRENT_ACCOUNT_RECEIVED_OFFER: '/current-account/received-offers',
  CURRENT_ACCOUNT_COLLECTION: '/current-account/collections',
  CURRENT_ACCOUNT_PAYMENT: '/current-account/payment',

  ACCOUNT: '/account/:accountAddress',
  ACCOUNT_LIKED: '/account/:accountAddress/liked',
  Account_ACTIVITY: '/account/:accountAddress/activities',

  COLLECTION_DETAIL: '/collection/:collectionAddress',
  COLLECTION_ACTIVITY: '/collection/:collectionAddress/activity',
  COLLECTION_EDIT: '/collection/:collectionAddress/edit',
  COLLECTION_PAYOUT: '/collection/:collectionAddress/payout',

  SEARCH_DETAIL: '/search',

  ITEM_DETAIL: '/item/:collectionAddress/:tokenId',
  ITEM_DETAIL_DETAIL: '/item/:collectionAddress/:tokenId',
  ITEM_DETAIL_ACTIVITY: '/item/:collectionAddress/:tokenId/activity',
  ITEM_DETAIL_OFFER: '/item/:collectionAddress/:tokenId/offer',

  ORIGINALS: '/originals',
  ORIGINALS_BRAND_PAGE: '/originals/:originalsId',
  ORIGINALS_CHECKOUT: '/originals/:originalsId/checkout',
  ORIGINALS_CHECKOUT_SUCCESS: '/originals/:originalsId/checkout/succeeded/:checkoutId',
  ORIGINALS_CHECKOUT_FAIL: '/originals/:originalsId/checkout/failed/:errorCode',
  ORIGINALS_CHECKOUT_CALLBACK: '/originals/:originalsId/checkout/:result/:errorCodeOrCheckoutId',
  ORIGINALS_RANKINGS: '/rankings/:originalsId',
  BURN_HOME: '/burn/:originalsId',
  BURN_CHECK: '/burn/:originalsId/:exchangeCheckoutId/check',
  BURN_SUCCESS: '/burn/:originalsId/:exchangeCheckoutId/success',
  BURN_FAIL: '/burn/:originalsId/fail',
}
