const DavinciTicketInformation = () => {
  return (
    <>
      <h1 className="text-lg">다빈치모텔 예약권 안내</h1>

      <table className="mt-4 rounded-lg bg-bgTertiary border-separate py-3 border-spacing-x-5 border-spacing-y-2 table-auto w-full">
        <thead>
          <tr>
            <th className="align-top text-sm text-start text-textSecondary font-normal">예약권</th>
            <th className="align-top text-sm text-start text-textSecondary font-normal">정상가</th>
            <th className="align-top text-sm text-start text-textSecondary font-normal whitespace-pre-line break-words">
              현대카드 20% {'\n'} 할인가
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-sm">DAY 1 예약권</td>
            <td className="text-sm">₩ 88,000</td>
            <td className="text-sm text-textAccent">₩ 70,400</td>
          </tr>
          <tr>
            <td className="text-sm">DAY 2 예약권</td>
            <td className="text-sm">₩ 132,000</td>
            <td className="text-sm text-textAccent">₩ 105,600</td>
          </tr>
          <tr>
            <td className="text-sm">DAY 3 예약권</td>
            <td className="text-sm">₩ 132,000</td>
            <td className="text-sm text-textAccent">₩ 105,600</td>
          </tr>
          <tr>
            <td className="text-sm">3일권</td>
            <td className="text-sm">₩ 298,000</td>
            <td className="text-sm text-textAccent">₩ 238,400</td>
          </tr>
        </tbody>
      </table>

      <ul className="mt-4 list-disc list-outside pl-[25px]">
        <li className="text-sm text-textSecondary">현대카드로 구매 시 20% 할인됩니다.</li>
        <li className="text-sm text-textSecondary">권종별 1인당 1장만 구매 가능합니다.</li>
        <li className="text-sm text-textSecondary">DAY 별 예약권과 3일권 교차 구매 불가합니다.</li>
      </ul>
    </>
  )
}

export default DavinciTicketInformation
