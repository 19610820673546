import { useQuery } from '@tanstack/react-query'

import { IAvailableCheckoutOrdNo } from '@/shared/types'

import withAxios from '../../utils/api'

export const checkAvailableOrdNo = async ({
  originalsId,
  ordNo,
}: {
  originalsId?: string
  ordNo?: string | null
}) => {
  const url = `/api/v1/app/public/payments/checkout?originalsId=${originalsId}&ordNo=${ordNo}`
  const { data } = await withAxios<IBaseResponse<IAvailableCheckoutOrdNo>>({
    url,
  })
  return data
}

export const useCheckAvailableOrdNo = (
  pathname: string,
  originalsId?: string,
  ordNo?: string | null,
) =>
  useQuery(
    [pathname, 'useCheckAvailableOrdNo', { originalsId, ordNo }],
    () => checkAvailableOrdNo({ originalsId, ordNo }),
    {
      enabled: originalsId !== undefined && ordNo !== undefined && ordNo !== null,
      retry: false,
    },
  )
