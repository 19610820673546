import { APP_PATH_TYPE } from '@/shared/constants'

// TODO @고영욱 다빈치모텔 행사 끝나고 APP_PATH_TYPE.DIVE은 지워야 합니다.
export const goToAppUsingDeepLink = (pathType: keyof typeof APP_PATH_TYPE, isDev?: boolean) => {
  const apn = 'com.kkrwallet'
  const ibi = `org.modernlion.kkrWallet${isDev ? 'Dev' : ''}`
  const urlMappings = {
    [APP_PATH_TYPE.HOME]: `https://konkrit.page.link/?link=https://konkrit.page.link?navigate%3Dhome&apn=${apn}&isi=123456789&ibi=${ibi}&efr=1`,
    [APP_PATH_TYPE.MYTEM]: `https://konkrit.page.link/?link=https://konkrit.page.link?navigate%3Dmytem&apn=${apn}&isi=123456789&ibi=${ibi}&efr=1`,
    [APP_PATH_TYPE.PAYMENT_HISTORY]: `https://konkrit.page.link/?link=https://konkrit.page.link?navigate%3DpaymentReceipts&apn=${apn}&isi=6444177523&ibi=${ibi}&efr=1`,
    [APP_PATH_TYPE.DIVE]: 'https://hyundai-dive.onelink.me/16nS/divetokkrt',
  }

  if (typeof window !== 'undefined') {
    window.location.href = urlMappings[pathType]
  }
}
