import { CheckBox } from '@/components/core-ui'
import { cx } from '@/shared/utils'

interface ITermsAndConditionsProps {
  title: string
  subTitle?: string
  isAll?: boolean
  checkValue: boolean
  hasDialog?: boolean
  wrapperClassNames?: string
  handleClickCheckValue: () => void
  handleClickDialog?: () => void
}

const TermsAndConditions = ({
  title,
  subTitle,
  isAll,
  hasDialog,
  checkValue,
  wrapperClassNames,
  handleClickDialog,
  handleClickCheckValue,
}: ITermsAndConditionsProps) => {
  return (
    <>
      <div className={cx(wrapperClassNames, 'flex justify-between items-center gap-x-6')}>
        <div
          className={cx(
            isAll ? 'w-full border-b pb-3 border-borderQuarternary' : '',
            'flex items-center gap-x-2',
          )}>
          <CheckBox checked={checkValue} onClick={handleClickCheckValue} size="sm" />
          <div className="flex flex-col justify-center items-start">
            <p className={cx('text-sm', isAll ? 'text-textPrimary' : 'text-textSecondary')}>
              {title}
            </p>
          </div>
        </div>
        {hasDialog && (
          <div
            onClick={handleClickDialog}
            className="underline text-sm cursor-pointer text-textSecondary">
            자세히보기
          </div>
        )}
      </div>
      {subTitle && (
        <p className="max-w-[226px] ml-8 text-[11px] text-textError whitespace-pre-line break-keep">
          {subTitle}
        </p>
      )}
    </>
  )
}

export default TermsAndConditions
