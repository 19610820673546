import { CHECKOUT_OPTION_THEME } from '@/shared/constants'
import { IGroupOption } from '@/shared/types'
import { cx } from '@/shared/utils'

interface IOptionGroupList {
  options: IGroupOption[]
  selectedOptionGroupName: string
  handleClickOptionGroupName: (title: string) => void
}

interface IOptionGroupItem {
  title: string
  isFirstItem: boolean
  isLastItem: boolean
  selectedOptionGroupName: string
  handleClickOptionGroupName: (title: string) => void
}

const OptionGroupItem = ({
  title,
  isFirstItem,
  isLastItem,
  selectedOptionGroupName,
  handleClickOptionGroupName,
}: IOptionGroupItem) => {
  return (
    <div
      onClick={() => handleClickOptionGroupName(title)}
      className={cx(
        isFirstItem && 'rounded-tl-md rounded-bl-md',
        isLastItem && 'rounded-tr-md rounded-br-md',
        title === selectedOptionGroupName
          ? 'border-borderPrimary bg-bgTertiary'
          : 'border-borderQuarternary',
        'w-full h-[53px] flex justify-center items-center border-[1px] cursor-pointer',
      )}>
      <p className="text-sm">{title}</p>
    </div>
  )
}

const OptionGroupList = ({
  theme = `${CHECKOUT_OPTION_THEME.radio}`,
  options,
  selectedOptionGroupName,
  handleClickOptionGroupName,
}: IOptionGroupList & {
  theme?: keyof typeof CHECKOUT_OPTION_THEME
}) => {
  if (theme === `${CHECKOUT_OPTION_THEME.flat}`) {
    return <></>
  }

  if (theme === `${CHECKOUT_OPTION_THEME.radio}`) {
    return (
      <>
        <h1 className="w-full text-lg mt-10">예약권 종류</h1>
        <div className="w-full h-auto flex mt-4">
          {options.map((optionItem, index) => (
            <OptionGroupItem
              key={`OptionGroupItem-${optionItem.groupId}`}
              title={optionItem.groupName}
              isFirstItem={index === 0}
              isLastItem={index === options.length - 1}
              selectedOptionGroupName={selectedOptionGroupName}
              handleClickOptionGroupName={handleClickOptionGroupName}
            />
          ))}
        </div>
      </>
    )
  }

  return <></>
}

OptionGroupList.Item = OptionGroupItem

export default OptionGroupList
