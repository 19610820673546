import { OPTION_SELECT_TYPE } from '@/shared/constants'
import { IPrice } from '@/shared/types'

interface ISelectedOptionItem {
  optionTitle: string
  optionSubTitle: string
  price: IPrice[]
  buyLimit: number
  totalCount: number
  metadataKey: string
  maxMintingCount: number
  optionSelectType?: keyof typeof OPTION_SELECT_TYPE
}

const getIsSelectedOtherOption = (
  selectedOptionList: ISelectedOptionItem[],
  target: ISelectedOptionItem,
) => {
  return selectedOptionList.some(item => item.optionSelectType !== target.optionSelectType)
}

export default getIsSelectedOtherOption
