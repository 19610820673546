import { useCallback, useEffect, useRef } from 'react'

import { Button } from '@/components/core-ui'
import Dialog from '@/components/core-ui/Dialog'
import { IDialogProps } from '@/shared/types'

function CancelRefundDialog({ isOpened, closeDialog }: IDialogProps) {
  const ref = useRef<HTMLDivElement | null>(null)

  const onFocusHandler = useCallback(() => {
    if (!isOpened || ref.current === null) return
    ref.current.scrollTop = 0
  }, [isOpened])

  useEffect(() => {
    setTimeout(() => {
      onFocusHandler()
    }, 100)
  }, [onFocusHandler])

  return (
    <Dialog isOpen={isOpened} handleClose={closeDialog}>
      <div
        onFocus={onFocusHandler}
        ref={ref}
        className="w-[280px] h-[580px] overflow-y-auto scrollbar-hide md:w-80 max-w-xs">
        <Dialog.Header title="환불 취소 정책 동의" />
        <div className="flex flex-col gap-3 py-5 text-sm text-textSecondary">
          <ul className="list-disc list-outside pl-5">
            <li>취소 환불 규정</li>
            <p className="ml-2"> - 구매 당일 밤 12시 전까지 : 전액 환급</p>
            <p className="ml-2"> - 공연일 10일 전까지 : 전액 환급</p>
            <p className="ml-2"> - 공연일 7일 전까지 : 10% 공제 후 환급</p>
            <p className="ml-2"> - 공연일 3일 전까지 : 20% 공제 후 환급</p>
            <p className="ml-2"> - 공연일 1일 전까지 : 30% 공제 후 환급</p>
            <p className="ml-2"> - 공연 당일 공연 시작 전까지 : 90% 공제 후 환급</p>
            <br />
            <li>기존 발행된 예약권은 사용 불가 처리로 변경됩니다.</li>
            <br />
            <li>취소/환불 완료 시 프로그램 예약 건이 초기화되므로 유의 부탁드립니다.</li>
            <br />
            <li>본 행사는 천재지변 등 불가피한 사정에 의해 취소, 변경될 수 있습니다.</li>
            <br />
            <li>
              본 행사의 세부사항은 출연자의 요청에 따라 변경될 수 있으며 특정 출연자의 취소에 따른
              예약권 환불은 불가합니다.
            </li>
            <br />
            <li>
              행사 당일 건물 내 주차가 불가합니다. 주차 문제로 입장이 지연되어 환불을 요구하시는
              경우에는 주최사 및 예매처는 일체의 책임을 지지 않으며 이와 같은 사유의 환불은
              불가합니다.
            </li>
            <br />
            <li>
              교통 문제로 프로그램 입장이 지연되어 환불을 요구하시는 경우, 행사 주최/주관/예매처는
              일체의 책임을 지지 않으며 이와 같은 사유의 환불은 불가합니다.
            </li>
            <br />
            <li>
              프로그램 시작 15분 전까지 대기라인에 도착하지 않을 경우 예약 권한이 취소되며, 이로
              인한 예약권 환불은 불가합니다.
            </li>
            <br />
            <li>
              환불 기준일 : 고객센터 상담접수일 / 채널톡 접수일로부터 영업일 기준{' '}
              <strong>최대 48시간 내</strong>
            </li>
            <p className="ml-2">
              - 블록체인 특성상 NFT 예약권 결제 취소 및 환불 완료까지 최대 48시간 소요될 수
              있습니다.
            </p>
          </ul>
        </div>
      </div>
      <Dialog.Footer>
        <Button text="확인" theme="Outlined" onClick={closeDialog} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default CancelRefundDialog
