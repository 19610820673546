import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'

import TermsAndConditions from './TermsAndConditions'

export interface IAdultTermsProps {
  checkedAdult: boolean | null
  handleClickAdult: () => void
}

export const AdultTerms = ({ checkedAdult, handleClickAdult }: IAdultTermsProps) => {
  const { setDialog } = useDialog()

  if (checkedAdult === null) {
    return <></>
  }

  return (
    <TermsAndConditions
      hasDialog
      title={'만 19세 이상 결제 동의 (필수)'}
      subTitle={'만 19세 미만의 경우, 결제하신 예약권이 자동으로 취소 처리됩니다.'}
      checkValue={checkedAdult}
      wrapperClassNames={'mt-3'}
      handleClickCheckValue={handleClickAdult}
      handleClickDialog={() => {
        setDialog(ManagedDialog.adult)
      }}
    />
  )
}
