import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useRemoveQueryParams = () => {
  const router = useRouter()

  return useCallback(
    (param: string) => {
      const { pathname, query } = router
      const params = new URLSearchParams(query as Record<string, string>)
      params.delete(param)
      router.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
    },
    [router],
  )
}
