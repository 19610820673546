/* eslint-disable @next/next/no-img-element */
import { Fragment } from 'react'

import { IPurchaseGuideType } from '@/shared/types'

const PurchaseGuide = ({ purchaseGuideData }: { purchaseGuideData: IPurchaseGuideType }) => {
  return (
    <div className="bg-bgTertiary rounded-lg px-4 py-5 mt-10">
      <div className="flex items-center gap-x-1">
        <img src="/img/icon/triangle-alert.svg" alt="purchase_alert_logo" />
        <h3 className="text-textAccent">{purchaseGuideData.title}</h3>
      </div>

      <ul className="mt-1.5 list-disc px-4">
        {purchaseGuideData.guides.map((item, index) => (
          <Fragment key={`purchase-guide-${index}`}>
            <li
              key={`purchase-guide-item-${item.id}-${index}`}
              className="ml-1 text-sm text-textPrimary whitespace-pre-line leading-normal mb-2">
              {item.guide}
            </li>
            {item.description && (
              <p className="text-xs text-textPrimary whitespace-pre-line leading-normal px-4 mb-2 break-keep">
                {item.description}
              </p>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  )
}

export default PurchaseGuide
