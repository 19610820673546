import { useQuery } from '@tanstack/react-query'

import { IOriginalsItemResponse } from '@/shared/types'
import withAxios from '@/shared/utils/api'

export const getOriginalsItem = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/v1/public/originals/${originalsId}`
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IOriginalsItemResponse>>({ url })
  return data
}

export const useGetOriginalsItem = (pathname: string, originalsId?: string) =>
  useQuery(
    [pathname, 'useGetOriginalsItem', { originalsId }],
    () => getOriginalsItem({ originalsId }),
    {
      refetchOnWindowFocus: false,
      enabled: originalsId !== undefined,
    },
  )
