const ErrorFallback = ({ error, resetBoundary }: { error?: Error; resetBoundary?: () => void }) => {
  return (
    <>
      <span data-testid="error-message">{error?.message}</span>
      <button data-testid="retry-button" onClick={resetBoundary}>
        재시도
      </button>
    </>
  )
}

export default ErrorFallback
