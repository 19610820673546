import { useId } from '@mantine/hooks'

interface ICheckBoxProps {
  size?: 'base' | 'sm'
  checked: boolean
  onClick: () => void
  id?: string
}

export default function CheckBox({ onClick, checked, id, size = 'base' }: ICheckBoxProps) {
  const sizeMappings = {
    base: [24, 24],
    sm: [20, 20],
  }
  const uuid = useId(id)
  return (
    <div className="w-6 h-6 overflow-hidden flex items-center justify-center">
      <input type="checkbox" id={uuid} checked={checked} readOnly className="hidden" />
      <label htmlFor={uuid} onClick={onClick} className="cursor-pointer">
        <svg
          width={sizeMappings[size][0]}
          height={sizeMappings[size][1]}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_802_4818)">
            <rect width="24" height="24" rx="12" fill={checked ? '#FFCC33' : '#3F3F46'} />
            <path
              d="M7.92049 11.5797C7.48115 11.1403 6.76884 11.1403 6.3295 11.5797C5.89016 12.019 5.89016 12.7313 6.3295 13.1707L9.3295 16.1707C9.76884 16.61 10.4812 16.61 10.9205 16.1707L17.6705 9.42067C18.1098 8.98133 18.1098 8.26901 17.6705 7.82967C17.2312 7.39033 16.5188 7.39033 16.0795 7.82967L10.125 13.7842L7.92049 11.5797Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_802_4818">
              <rect width="24" height="24" rx="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </label>
    </div>
  )
}
