import { ManagedDialog } from '@/shared/constants'
import { useDialog } from '@/shared/hooks'

import TermsAndConditions from './TermsAndConditions'

export interface ICancelRefundTermsProps {
  checkedCancelRefund: boolean | null
  handleClickCancelRefund: () => void
}

export const CancelRefundTerms = ({
  checkedCancelRefund,
  handleClickCancelRefund,
}: ICancelRefundTermsProps) => {
  const { setDialog } = useDialog()

  if (checkedCancelRefund === null) {
    return <></>
  }

  return (
    <TermsAndConditions
      hasDialog
      title={'환불 취소 정책 동의 (필수)'}
      wrapperClassNames={'mt-3'}
      checkValue={checkedCancelRefund}
      handleClickCheckValue={handleClickCancelRefund}
      handleClickDialog={() => {
        setDialog(ManagedDialog.cancelRefund)
      }}
    />
  )
}
