import TermsAndConditions from './TermsAndConditions'

export interface IAllAgreeTermsProps {
  checkedAllAgree: boolean | null
  handleClickAllAgree: () => void
}

export const AllAgreeTerms = ({ checkedAllAgree, handleClickAllAgree }: IAllAgreeTermsProps) => {
  if (checkedAllAgree === null) {
    return <></>
  }

  return (
    <>
      <TermsAndConditions
        isAll
        wrapperClassNames={'mt-[60px]'}
        checkValue={checkedAllAgree}
        handleClickCheckValue={handleClickAllAgree}
        title={'아래 내용에 모두 동의합니다. (필수)'}
      />
    </>
  )
}
