import bigDecimal from 'js-big-decimal'

import { ORIGINALS_PAYMENT_TYPE } from '@/shared/constants'
import { ISelectedOption } from '@/shared/types'

/**
 *
 * @param selectedOptionList 클릭한 옵션
 * @param selectedPaymentType 클릭한 결제 수단
 * @returns 결제 수단에 따라 클릭한 옵션 총합을 계산합니다.
 */
const getTotalPriceForFestival = (
  selectedOptionList: ISelectedOption[],
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE,
): number => {
  const totalPrice = selectedOptionList.reduce((acc, current) => {
    const selectedPaymentTypePrice = current.price.find(
      priceItem => priceItem.type === selectedPaymentType,
    )?.value

    if (selectedPaymentTypePrice === undefined) return acc

    return parseInt(bigDecimal.add(acc, selectedPaymentTypePrice), 10)
  }, 0)

  return totalPrice
}

export default getTotalPriceForFestival
