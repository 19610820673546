import { IStockInfo } from '@/shared/types'

const getIsSoldOut = (
  targetMetadataKey: string,
  stockInfoList: IStockInfo[] | null | undefined,
) => {
  if (!stockInfoList) {
    return false
  }

  return stockInfoList.some(
    ({ metadataKey, stockCount }) => metadataKey === targetMetadataKey && stockCount === 0,
  )
}

export default getIsSoldOut
