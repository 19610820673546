import { useCallback, useEffect, useState } from 'react'

import { LocalStorage, sendMessageToApp, sendToSentryWithExtra } from '../utils'

export const useGetOrdNo: () => {
  data: string | null
  requestOrdNo: () => void
  isLoading: boolean
} = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<string | null>(null)

  const requestOrdNo = useCallback(() => {
    setData(null)
    setIsLoading(true)
    LocalStorage.removeItem('ordNo')
    sendMessageToApp<string>('Message', 'RequestOrdNo')
  }, [])

  const listener = (event: any) => {
    if (!window.ReactNativeWebView || event.data === 'undefined') return

    const { type, data } = JSON.parse(event.data)
    if (type === 'OrdNo') {
      setData(data)
    }
    if (type === 'ErrorOrdNo') {
      sendToSentryWithExtra(new Error('useGetOrdNo: cannot get ordNo'))
      requestOrdNo()
    }
  }

  useEffect(() => {
    ;(() => {
      document.addEventListener('message', listener)
      window.addEventListener('message', listener)
    })()
    return () => {
      document.removeEventListener('message', listener)
      window.removeEventListener('message', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { data, requestOrdNo, isLoading }
}
