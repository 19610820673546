import { useMutation } from '@tanstack/react-query'

import { IGetOriginalsPaymentCallbackResponse, ISelectedCheckoutOptionItem } from '@/shared/types'

import withAxios from '../../utils/api'

export const postOriginalsFreePaymentsCallback = async ({
  ordNo,
  originalsId,
  selectOptions,
}: {
  ordNo: string
  originalsId: string
  selectOptions: ISelectedCheckoutOptionItem[]
}) => {
  const url = '/api/v1/app/public/free-payments/callback'
  const {
    data: { data },
  } = await withAxios<IBaseResponse<IGetOriginalsPaymentCallbackResponse>>({
    url,
    method: 'POST',
    data: {
      ordNo,
      originalsId,
      selectOptions,
    },
  })
  return data
}

export const usePostOriginalsFreePaymentsCallback = () => {
  return useMutation(
    ['usePostOriginalsFreePaymentsCallback'],
    ({
      ordNo,
      originalsId,
      selectOptions,
    }: {
      ordNo: string
      originalsId: string
      selectOptions: ISelectedCheckoutOptionItem[]
    }) => postOriginalsFreePaymentsCallback({ ordNo, originalsId, selectOptions }),
  )
}
