import { ORIGINALS_PAYMENT_TYPE, ORIGINALS_PROJECT_TYPE } from '@/shared/constants'
import {
  ISelectedCheckoutOptionItem,
  ISelectedOption,
  IUpdateOriginalsCheckoutProps,
  OriginalsProjectType,
} from '@/shared/types'

export const convertUpdateCheckoutInfoProps = ({
  ordNo,
  amount,
  originalsId,
  projectType,
  metadataKey,
  selectedOptionList,
  selectedPaymentType,
}: {
  ordNo: string
  amount: number
  originalsId: string
  metadataKey: string | null
  projectType: OriginalsProjectType
  selectedOptionList: ISelectedOption[]
  selectedPaymentType: keyof typeof ORIGINALS_PAYMENT_TYPE
}): IUpdateOriginalsCheckoutProps => {
  const resultSelectedOptionList: ISelectedCheckoutOptionItem[] = []

  const result = {
    ordNo,
    originalsId,
    paymentType: selectedPaymentType,
  }

  if (projectType === ORIGINALS_PROJECT_TYPE.FESTIVAL) {
    const resultSelectedOptionList = selectedOptionList.map(
      (selectedOptionItem: ISelectedOption) => {
        const { metadataKey, productCount } = selectedOptionItem

        return { metadataKey, productCount }
      },
    )

    return {
      ...result,
      selectOptions: resultSelectedOptionList,
      totalCount: selectedOptionList.length.toString(),
    }
  }

  if (metadataKey !== null) {
    resultSelectedOptionList.push({
      metadataKey,
      productCount: amount.toString(),
    })

    return {
      ...result,
      selectOptions: resultSelectedOptionList,
      totalCount: amount.toString(),
    }
  }

  return {
    ...result,
    selectOptions: resultSelectedOptionList,
    totalCount: amount.toString(),
  }
}
